<template>
  <div>
    <div class="notLoggedIn">
      <div class="new-customer-details new-login">
        <div class="order-card mb-30 login-form-box">
          <div class="order-title">
            <span><font-awesome-icon icon="user"/></span>
            {{ $t("my_information") }}
          </div>
          <div>
            <div class="form-group-wrap">
              <b-form-group
                :label="$t('form_email')"
                label-for="email"
                label-class="required"
              >
                <inputField
                  :isInputValid="form.emailState"
                  id="email"
                  :inputValue="form.email"
                  @setValue="validateEmail"
                  inputType="text"
                  inputPlaceHolder="emailadres@gmail.com"
                  ariaOfInput="email-feedback"
                />

                <b-form-invalid-feedback id="email-feedback">
                  {{ form.emailError }}
                </b-form-invalid-feedback>
              </b-form-group>
            </div>
            <div
              class="form-group-wrap"
              v-if="form.emailState && emailExists === false"
            >
              <b-form-checkbox
                id="checkbox-3"
                name="checkbox-3"
                v-model="isCreateAccount"
                @change="setCreateAccount($event)"
                >{{ $t("create_account_checkout") }}</b-form-checkbox
              >
              <b-form-checkbox
                id="checkbox-3"
                name="checkbox-3"
                v-model="isNotCreateAccount"
                @change="setCreateAccount($event)"
                >{{ $t("no_create_account_checkout") }}</b-form-checkbox
              >
              <div v-if="isCreateAccount && form.emailState">
                <b-form-group
                  id="create-acc-password-group"
                  :label="$t('form_password')"
                  label-for="password"
                >
                  <!-- <inputField
                    id="create-acc-password"
                    :isInputValid="form.passwordState"
                    :inputValue="form.password"
                    @setValue="validatePassword"
                    inputType="password"
                    ariaOfInput="create-acc-password-feedback"
                  />
                  <client-only>
                    <password-meter :password="form.password" />
                  </client-only> -->
                  <div class="form-group password-watch">
                    <b-form-input
                      v-model="form.password"
                      :type="form.createAccountPasswordFieldType"
                      :placeholder="$t('password')"
                      id="create-acc-password"
                      :state="form.passwordState"
                      ariaOfInput="create-acc-password-feedback"
                      class="form-item"
                      @setValue="validatePassword"
                    >
                    </b-form-input>
                    <span class="show-password">
                      <b-link
                        class="span-txt"
                        @click="toggleShowPassCreateAccount"
                      >
                        {{
                          form.createAccountPasswordFieldType === "password"
                            ? $t("show")
                            : $t("hide")
                        }}
                      </b-link>
                    </span>
                  </div>
                  <b-form-invalid-feedback id="create-acc-password-feedback">
                    {{ form.passwordError }}
                  </b-form-invalid-feedback>
                </b-form-group>
                <b-form-group
                  id="create-acc-password-confirm-group"
                  :label="$t('form_password_confirmation') + '*'"
                  label-for="password_confirm"
                >
                  <!-- <inputField
                    id="create-acc-password-confirm"
                    :isInputValid="form.password_confirmState"
                    :inputValue="form.password_confirm"
                    @setValue="validateConfirmationPassword"
                    inputType="password"
                    ariaOfInput="create-acc-password-confirm-feedback"
                  /> -->
                  <div class="form-group password-watch">
                    <b-form-input
                      v-model="form.password_confirm"
                      :type="form.createAccountConfirmPasswordFieldType"
                      :placeholder="$t('password')"
                      id="create-acc-password-confirm"
                      :state="form.password_confirmState"
                      ariaOfInput="create-acc-password-confirm-feedback"
                      class="form-item"
                      @setValue="validateConfirmationPassword"
                    >
                    </b-form-input>
                    <span class="show-password">
                      <b-link
                        class="span-txt"
                        @click="toggleShowConfirmPassCreateAccount"
                      >
                        {{
                          form.createAccountConfirmPasswordFieldType ===
                          "password"
                            ? $t("show")
                            : $t("hide")
                        }}
                      </b-link>
                    </span>
                  </div>
                  <b-form-invalid-feedback
                    id="create-acc-password-confirm-feedback"
                  >
                    {{ form.password_confirmError }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </div>
            </div>
            <div
              class="form-group-wrap"
              v-if="form.emailState === true && emailExists === true"
            >
              <b-form-group
                class="account-inputs"
                id="password-group"
                :label="$t('form_password')"
                label-for="password"
                label-class="required"
              >
                <div class="form-group password-watch">
                  <b-form-input
                    v-model="form.password"
                    :type="form.passwordFieldType"
                    :placeholder="$t('password')"
                    id="text-password"
                    :state="form.passwordState"
                    aria-describedby="password-feedback"
                    class="form-item"
                  >
                  </b-form-input>
                  <span class="show-password">
                    <b-link class="span-txt" @click="toggleShowPass">
                      {{
                        form.passwordFieldType === "password"
                          ? $t("show")
                          : $t("hide")
                      }}
                    </b-link>
                  </span>
                </div>

                <b-form-invalid-feedback id="password-feedback">
                  {{ form.passwordError }}
                </b-form-invalid-feedback>
              </b-form-group>
              <span class="forgot-password">
                <b-link to="/forgot-password/" class="">
                  {{ $t("password_forgot") }}</b-link
                >
              </span>
              <div class="text-center">
                <b-button @click="doLogin" class="green-btn login-continue"
                  >{{ $t("login_continue") }}
                  <span><font-awesome-icon icon="chevron-circle-right"/></span>
                </b-button>
              </div>
            </div>
          </div>
        </div>

        <!-- Add Address -->
        <div
          class="order-card new-login"
          v-if="emailExists === false && form.emailState"
        >
          <div class="order-title">
            <span><font-awesome-icon icon="home"/></span>
            {{ $t("shipping_address") }}
          </div>
          <div>
            <!-- Is company checkbox -->
            <b-form-checkbox
              id="checkbox-1"
              v-model="isCompany"
              name="checkbox-1"
              class="pl-30"
              >{{ $t("form_are_you_a_company") }}</b-form-checkbox
            >

            <!-- Company Detail Form -->
            <div v-if="isCompany" class="short-form">
              <!-- Company Name -->
              <div class="form-group-wrap">
                <b-form-group
                  class="account-inputs"
                  id="company-group"
                  :label="$t('form_company_name')"
                  label-class="required"
                  label-for="company"
                >
                  <b-form-input
                    id="company"
                    :state="form.companyState"
                    v-model="form.company"
                    v-on:blur="validateCompany()"
                    inputType="text"
                    ariaOfInput="company-feedback"
                  />
                  <b-form-invalid-feedback id="company-feedback">
                    {{ form.companyError }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </div>

              <!-- COC Number -->
              <div class="form-group-wrap">
                <b-form-group
                  class="account-inputs"
                  id="coc-group"
                  :label="$t('form_coc_number')"
                  label-for="coc"
                >
                  <b-form-input
                    id="coc"
                    :state="form.cocState"
                    :value="form.coc"
                    @input="validateCOCNumber"
                    inputType="text"
                    ariaOfInput="coc-feedback"
                  />
                  <b-form-invalid-feedback id="coc-feedback">
                    {{ form.cocError }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </div>

              <!-- VAT -->
              <div class="form-group-wrap">
                <b-form-group
                  class="account-inputs"
                  id="taxvat-group"
                  :label="$t('form_vat_number')"
                  label-for="taxvat"
                >
                  <b-form-input
                    id="taxvat"
                    :state="form.taxvatState"
                    :value="form.taxvat"
                    @input="validateVAT"
                    inputType="text"
                    ariaOfInput="taxvat-feedback"
                    ref="taxvatInput"
                  />
                  <b-form-invalid-feedback id="taxvat-feedback">
                    {{ form.taxvatError }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </div>
            </div>

            <!-- First Name -->
            <div class="form-group-wrap">
              <b-form-group
                class="account-inputs"
                id="first_name-group"
                :label="$t('form_first_name')"
                label-class="required"
                label-for="first_name"
              >
                <b-form-input
                  id="first_name"
                  :state="form.firstnameState"
                  v-model="form.firstname"
                  v-on:blur="validateFirstname()"
                  inputType="text"
                  ariaOfInput="firstname-feedback"
                />
                <b-form-invalid-feedback id="firstname-feedback">
                  {{ form.firstnameError }}
                </b-form-invalid-feedback>
              </b-form-group>
            </div>

            <!-- Last Name -->
            <div class="form-group-wrap">
              <b-form-group
                class="account-inputs"
                id="last_name-group"
                :label="$t('form_last_name')"
                label-for="last_name"
                label-class="required"
              >
                <b-form-input
                  id="last_name"
                  :state="form.lastnameState"
                  v-model="form.lastname"
                  v-on:blur="validateLastname()"
                  inputType="text"
                  ariaOfInput="lastname-feedback"
                />
                <b-form-invalid-feedback id="lastname-feedback">
                  {{ form.lastnameError }}
                </b-form-invalid-feedback>
              </b-form-group>
            </div>

            <!-- Country -->
            <div class="form-group-wrap mt-2">
              <b-form-group
                class="account-inputs"
                id="country-group"
                :label="$t('form_country')"
                label-class="required"
                label-for="country"
              >
                <b-form-select
                  id="country"
                  v-model="form.address.country_code"
                  :options="countries"
                  class="select"
                ></b-form-select>
              </b-form-group>
            </div>

            <!-- Postcode -->
            <div class="form-group-wrap">
              <b-form-group
                v-if="!showFullAddress"
                class="account-inputs"
                id="postcode-group"
                :label="$t('form_postal_code')"
                label-class="required"
                label-for="postcode"
              >
                <b-form-input
                  id="postcode"
                  :state="form.address.postcodeState"
                  v-model="form.address.postcode"
                  @blur="validatePostcode()"
                  @input="validatePostcode"
                  inputType="text"
                  ariaOfInput="address.postcode-feedback"
                  :length="postcodeLength"
                  :inputPlaceHolder="postcodePlaceholder"
                />
                <b-form-invalid-feedback id="address.postcode-feedback">
                  {{ form.address.postcodeError }}
                </b-form-invalid-feedback>
              </b-form-group>

              <!-- Street -->
              <b-form-group
                v-show="showFullAddress"
                class="account-inputs"
                id="street-group"
                :label="$t('form_street')"
                label-class="required"
                label-for="street"
              >
                <b-form-input
                  id="street"
                  :state="form.address.streetDisplayState"
                  v-model="form.address.streetDisplay"
                  v-on:blur="validateStreet()"
                  inputType="text"
                  ariaOfInput="address.streetDisplay-feedback"
                />
                <b-form-invalid-feedback id="address.streetDisplay-feedback">
                  {{ form.address.streetDisplayError }}
                </b-form-invalid-feedback>
              </b-form-group>
            </div>

            <!-- House Number and Additional -->
            <b-row>
              <b-col cols="6" lg="6" md="12" sm="6">
                <div class="form-group-wrap">
                  <b-form-group
                    class="account-inputs"
                    id="house_number-group"
                    :label="$t('form_house_number')"
                    label-class="required"
                    label-for="house_number"
                  >
                    <b-form-input
                      id="house_number"
                      :state="form.address.house_numberState"
                      v-model="form.address.house_number"
                      v-on:blur="validateHouseNumber()"
                      inputType="text"
                      ariaOfInput="address.house_number-feedback"
                    />
                    <b-form-invalid-feedback id="address.house_number-feedback">
                      {{ form.address.house_numberError }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </div>
              </b-col>
              <b-col cols="6" lg="6" md="12" sm="6">
                <div class="form-group-wrap">
                  <b-form-group
                    class="account-inputs"
                    id="addition-group"
                    :label="$t('form_addition')"
                    label-for="addition"
                  >
                    <b-form-input
                      id="addition"
                      v-model="form.address.addition"
                      type="text"
                    ></b-form-input>
                  </b-form-group>
                </div>
              </b-col>
            </b-row>

            <div class="form-group-wrap">
              <!-- Postcode -->
              <div class="form-group-wrap">
                <b-form-group
                  v-if="showFullAddress"
                  class="account-inputs"
                  id="postcode-group"
                  :label="$t('form_postal_code')"
                  label-class="required"
                  label-for="postcode1"
                >
                  <b-form-input
                    id="postcode1"
                    :state="form.address.postcodeState"
                    :value="form.address.postcode"
                    @input="validatePostcode"
                    inputType="text"
                    ariaOfInput="address.postcode1-feedback"
                    :inputPlaceHolder="postcodePlaceholder"
                  />
                  <b-form-invalid-feedback id="address.postcode1-feedback">
                    {{ form.address.postcodeError }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </div>

              <!-- City -->
              <div class="form-group-wrap">
                <b-form-group
                  v-if="showFullAddress"
                  class="account-inputs"
                  id="city-group"
                  :label="$t('form_city')"
                  label-class="required"
                  label-for="city"
                >
                  <b-form-input
                    id="city"
                    :state="form.address.cityState"
                    v-model="form.address.city"
                    v-on:blur="validateCity()"
                    inputType="text"
                    ariaOfInput="address.city-feedback"
                  />
                  <b-form-invalid-feedback id="address.city-feedback">
                    {{ form.address.cityError }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </div>

              <b-form-group
                class="account-inputs"
                id="add-group"
                label=" "
                label-for="add"
                v-show="!showFullAddress"
              >
                <b-form-input
                  v-model="foundAddress"
                  id="add"
                  type="text"
                  readonly="readonly"
                ></b-form-input>
              </b-form-group>
              <b-form-group
                v-if="foundAddresses.length > 0 && showFullAddress == false"
                class="account-inputs"
                id="addm-group"
                label=" "
                label-for="addm"
              >
                <div id="addm">
                  <b-form-radio
                    class="account-radios"
                    v-model="selectedAddress"
                    v-for="(item, index) in foundAddresses"
                    :key="`nl-${index}`"
                    name="some-radios"
                    :value="index"
                    >{{ item.text }}</b-form-radio
                  >
                </div>
              </b-form-group>
              <b-form-group
                class="account-inputs"
                id="manual-group"
                label=" "
                label-for="manual"
              >
                <b-form-checkbox
                  v-if="showManual"
                  id="manual"
                  v-model="showFullAddress"
                  name="manual"
                  class="pl-30"
                  >{{ $t("form_fillout_address_manual") }}</b-form-checkbox
                >
              </b-form-group>

              <!-- Phone Number -->
              <b-form-group
                class="account-inputs"
                id="telephone-group"
                :label="$t('form_phone')"
                label-class="required"
                label-for="telephone"
              >
                <b-form-input
                  id="telephone"
                  :state="form.address.telephoneState"
                  v-model="form.address.telephone"
                  @blur="validatePhone()"
                  inputType="text"
                  ariaOfInput="address.telephone-feedback"
                />
                <b-form-invalid-feedback id="address.telephone-feedback">
                  {{ form.address.telephoneError }}
                </b-form-invalid-feedback>
              </b-form-group>
            </div>

            <!-- Billing Address Checkbox -->
            <div class="order-check-box">
              <b-form-group id="input-group-12">
                <b-form-checkbox v-model="form.address.default_billing"
                  ><span class="">{{
                    $t("billing_delivery_adddress_same")
                  }}</span></b-form-checkbox
                >
              </b-form-group>

              <!-- Billing Address Form -->
              <div class="group" v-if="form.address.default_billing == false">
                <div class="order-title">
                  <span><font-awesome-icon icon="home"/></span>
                  {{ $t("invoice_address") }}
                </div>

                <!-- Is company checkbox -->
                <div class="form-group">
                  <b-form-checkbox
                    id="checkbox-99"
                    v-model="isInvoiceCompany"
                    name="checkbox-99"
                    >{{ $t("form_are_you_a_company") }}</b-form-checkbox
                  >
                </div>

                <!-- Is Invoice Company -->
                <div v-if="isInvoiceCompany" class="short-form">
                  <div class="order-title">
                    <span><font-awesome-icon icon="home"/></span>
                    {{ $t("invoice_address") }}
                  </div>

                  <!-- Billing Company Name -->
                  <div class="form-group-wrap">
                    <b-form-group
                      class="account-inputs"
                      id="company-group"
                      :label="$t('form_company_name')"
                      label-class="required"
                      label-for="company"
                    >
                      <b-form-input
                        id="company"
                        :state="form.invoice.companyState"
                        v-model="form.invoice.company"
                        v-on:blur="validateInvoiceCompany()"
                        inputType="text"
                        ariaOfInput="company-feedback"
                      />
                      <b-form-invalid-feedback id="company-feedback">
                        {{ form.invoice.companyError }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </div>

                  <!-- Billing COC Number -->
                  <div class="form-group-wrap">
                    <b-form-group
                      class="account-inputs"
                      id="coc-group"
                      :label="$t('form_coc_number')"
                      label-class="required"
                      label-for="coc"
                    >
                      <b-form-input
                        id="coc"
                        :state="form.invoice.cocState"
                        :value="form.invoice.coc"
                        @input="validateInvoiceCOCNumber"
                        inputType="text"
                        ariaOfInput="coc-feedback"
                      />
                      <b-form-invalid-feedback id="coc-feedback">
                        {{ form.invoice.cocError }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </div>

                  <!-- VAT -->
                  <div class="form-group-wrap">
                    <b-form-group
                      class="account-inputs"
                      id="taxvat-group"
                      :label="$t('form_vat_number')"
                      label-for="taxvat"
                    >
                      <b-form-input
                        id="taxvat"
                        :state="form.invoice.taxvatState"
                        :value="form.invoice.taxvat"
                        @input="validateInvoiceVAT"
                        inputType="text"
                        ariaOfInput="taxvat-feedback"
                        ref="invoicevatInput"
                      />
                      <b-form-invalid-feedback id="taxvat-feedback">
                        {{ form.invoice.taxvatError }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </div>
                </div>

                <!-- First Name -->
                <div class="form-group-wrap">
                  <b-form-group
                    class="account-inputs"
                    id="first_name-group"
                    :label="$t('form_first_name')"
                    label-class="required"
                    label-for="first_name"
                  >
                    <b-form-input
                      id="first_name"
                      :state="form.invoice.firstnameState"
                      v-model="form.invoice.firstname"
                      v-on:blur="validateInvoiceFirstname()"
                      inputType="text"
                      ariaOfInput="firstname-feedback"
                    />
                    <b-form-invalid-feedback id="firstname-feedback">
                      {{ form.invoice.firstnameError }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </div>

                <!-- Last Name -->
                <div class="form-group-wrap">
                  <b-form-group
                    class="account-inputs"
                    id="last_name-group"
                    :label="$t('form_last_name')"
                    label-class="required"
                    label-for="last_name"
                  >
                    <b-form-input
                      id="last_name"
                      :state="form.invoice.lastnameState"
                      v-model="form.invoice.lastname"
                      v-on:blur="validateInvoiceLastname()"
                      inputType="text"
                      ariaOfInput="lastname-feedback"
                    />
                    <b-form-invalid-feedback id="lastname-feedback">
                      {{ form.invoice.lastnameError }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </div>

                <!-- Country -->
                <div class="form-group-wrap">
                  <b-form-group
                    class="account-inputs"
                    id="country-group"
                    :label="$t('form_country')"
                    label-class="required"
                    label-for="country"
                  >
                    <b-form-select
                      id="country"
                      v-model="form.invoice.address.country_code"
                      :options="countries"
                      class="select"
                    ></b-form-select>
                  </b-form-group>
                </div>

                <!-- Postcode -->
                <div class="form-group-wrap">
                  <b-form-group
                    class="account-inputs"
                    id="postcode-group"
                    :label="$t('form_postal_code')"
                    label-class="required"
                    label-for="postcode"
                  >
                    <b-form-input
                      id="postcode"
                      :state="form.invoice.address.postcodeState"
                      v-model="form.invoice.address.postcode"
                      v-on:blur="validateInvoicePostcode()"
                      inputType="text"
                      ariaOfInput="address.invoice.postcode-feedback"
                      :inputPlaceHolder="postcodePlaceholder"
                      :length="postcodeLength"
                    />
                    <b-form-invalid-feedback
                      id="address.invoice.postcode-feedback"
                    >
                      {{ form.invoice.address.postcodeError }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </div>

                <!-- House Number and Additional -->
                <b-row>
                  <b-col cols="6" lg="6" md="12" sm="6">
                    <div class="form-group-wrap">
                      <b-form-group
                        class="account-inputs"
                        id="house_number-group"
                        :label="$t('form_house_number')"
                        label-class="required"
                        label-for="house_number"
                      >
                        <inputField
                          id="house_number"
                          :isInputValid="form.invoice.address.house_numberState"
                          :inputValue="form.invoice.address.house_number"
                          @setValue="validateInvoiceHouseNumber"
                          inputType="text"
                          ariaOfInput="address.invoice.house_number-feedback"
                        />
                        <b-form-invalid-feedback
                          id="address.invoice.house_number-feedback"
                        >
                          {{ form.invoice.address.house_numberError }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </div>
                  </b-col>
                  <b-col cols="6" lg="6" md="12" sm="6"
                    ><div class="form-group-wrap">
                      <b-form-group
                        class="account-inputs"
                        id="addition-group"
                        :label="$t('form_addition')"
                        label-for="addition"
                      >
                        <b-form-input
                          id="addition"
                          v-model="form.invoice.address.addition"
                          type="text"
                        ></b-form-input>
                      </b-form-group></div
                  ></b-col>
                </b-row>

                <!-- Street -->
                <div class="form-group-wrap">
                  <b-form-group
                    class="account-inputs"
                    id="street-group"
                    :label="$t('form_street')"
                    label-class="required"
                    label-for="street"
                  >
                    <b-form-input
                      id="street"
                      :state="form.invoice.address.streetDisplayState"
                      v-model="form.invoice.address.streetDisplay"
                      v-on:blur="validateInvoiceStreet()"
                      inputType="text"
                      ariaOfInput="address.invoice.streetDisplay-feedback"
                    />
                    <b-form-invalid-feedback
                      id="address.invoice.streetDisplay-feedback"
                    >
                      {{ form.invoice.address.streetDisplayError }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </div>

                <!-- City -->
                <div class="form-group-wrap">
                  <b-form-group
                    class="account-inputs"
                    id="city-group"
                    :label="$t('form_city')"
                    label-class="required"
                    label-for="city"
                  >
                    <b-form-input
                      id="city"
                      :state="form.invoice.address.cityState"
                      v-model="form.invoice.address.city"
                      v-on:blur="validateInvoiceCity()"
                      inputType="text"
                      ariaOfInput="form.invoice.address.city-feedback"
                    />
                    <b-form-invalid-feedback
                      id="form.invoice.address.city-feedback"
                    >
                      {{ form.invoice.address.cityError }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </div>

                <!-- Phone Number -->
                <div class="form-group-wrap">
                  <b-form-group
                    class="account-inputs"
                    id="telephone-group"
                    :label="$t('form_phone')"
                    label-class="required"
                    label-for="telephone"
                  >
                    <b-form-input
                      id="invoiceTelephone"
                      :state="form.invoice.address.telephoneState"
                      v-model="form.invoice.address.telephone"
                      v-on:blue="validateInvoicePhone()"
                      @input="validateInvoicePhone"
                      inputType="text"
                      :inputPlaceHolder="phonenumberPlaceholder"
                      ariaOfInput="form.invoice.address.telephone-feedback"
                    />
                    <b-form-invalid-feedback
                      id="form.invoice.address.telephone-feedback"
                    >
                      {{ form.invoice.address.telephoneError }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </div>
              </div>
            </div>
            <!-- <p class="validation-note">*{{ $t("required_field") }}</p> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import config from "@config";
import {
  checkEmailExist,
  validatePostcode,
  validateVatNumber,
} from "@storefront/core/data-resolver/user";
import Cart from "@storefront/core/modules/cart/mixins";
// import ClientOnly from "vue-client-only";
// import { isServer } from "@storefront/core/helpers";
import { Logger } from "@storefront/core/lib/logger";
import inputField from "../input/inputField.vue";
import { isMobile } from "mobile-device-detect";

export default {
  name: "NotLoggedInCheckoutDetails",
  mixins: [Cart],
  components: {
    inputField,
  },
  mounted() {
    if (typeof config.customers.defaultCountry != "undefined") {
      this.form.address.country_code = config.customers.defaultCountry;
      this.form.invoice.address.country_code = config.customers.defaultCountry;
    }
    if (config.vat_validation.enabled == false) {
      this.showVatCheck = false;
    }
    if (this.guestEmail != null) {
      this.form.email = this.guestEmail;
    }
    this.$root.$on("checkFields", () => {
      this.checkFields();
    });
    this.$root.$on("subscribe_newsletter", (value) => {
      if (this.SubscribeNewsletter !== value) {
        this.SubscribeNewsletter = value;
      }
    });
  },
  methods: {
    async validateEmail(data) {
      if (data || data === "") {
        this.form.email = data;
      }

      if (this.form.email.length == 0) {
        this.form.emailState = false;
        this.form.emailError = this.$t("email_is_required_field");

        return false;
      }
      if (!this.emailReg.test(this.form.email)) {
        this.form.emailState = false;
        this.form.emailError = this.$t("email_is_invalid_field");

        return false;
      }
      if (!(await checkEmailExist(this.form.email))) {
        this.emailExists = true;
      } else {
        this.emailExists = false;
      }
      this.form.emailState = true;

      this.$store.dispatch("cart/addCartEmail", {
        email: this.form.email,
      });

      return true;
    },
    async doLogin() {
      const loginOk = await this.$store.dispatch("user/login", {
        username: this.form.email,
        password: this.form.password,
      });

      if (loginOk == true) {
        const msg = {
          type: "success",
          title: this.$t("login_success"),
          text: this.$t("you_are_logged_in"),
        };
        this.$store.dispatch("messages/sendMessage", { message: msg });
      }
    },
    async validateVAT(data) {
      if (data || data === "") {
        this.form.taxvat = data;
      }
      if (this.isCompany == true && data) {
        if (this.form.taxvat != "") {
          if (
            this.form.taxvat.substring(0, 2).toUpperCase() ===
            this.form.address.country_code
          ) {
            this.form.taxvat = this.form.taxvat.replace(/[.\s]/g, "");
            this.$refs.taxvatInput.$el.value = this.form.taxvat;

            const retval = await validateVatNumber(this.form.taxvat);
            if (retval.is_valid == true) {
              this.isValidVatNumber = true;
              this.form.taxvatState = true;
            } else {
              this.isValidVatNumber = false;
              this.form.taxvatState = false;
              this.form.taxvatError = this.$t("taxvat_invalid");

              this.showVatMessage = this.$t("taxvat_invalid");
            }
          } else {
            this.isValidVatNumber = false;
            this.form.taxvatState = false;
            this.form.taxvatError = this.$t("taxvat_wrong_country");
          }
        } else {
          this.form.taxvatState = null;
          this.form.taxvatError = "";
        }
      }
    },
    async validateAddress() {
      let isOk = true;
      let isAddress = true;

      if (this.isCompany == true) {
        if (this.form.company == "") {
          isOk = false;
        }
      }
      if (this.form.firstname == "") {
        isOk = false;
      }
      if (this.form.lastname == "") {
        isOk = false;
      }
      if (this.form.address.country_code == "") {
        isOk = false;
      }
      if (!this.form.address.postcodeState) {
        isOk = false;
      }
      if (!this.form.address.telephoneState) {
        isOk = false;
      }
      if (isOk == true && !isMobile) {
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }

      if (this.form.address.house_number == "") {
        isAddress = false;
      }
      if (this.form.address.streetDisplay == "") {
        isAddress = false;
      }
      if (this.form.address.city == "") {
        isAddress = false;
      }

      if (isOk == true && isAddress == true) {
        const address = {
          firstname: this.form.firstname,
          lastname: this.form.lastname,
          postcode: this.form.address.postcode,
          city: this.form.address.city,
          telephone: this.form.address.telephone,
          street: `${this.form.address.streetDisplay} ${this.form.address.house_number}${this.form.address.addition}`,
          country_code: this.form.address.country_code,
        };
        if (this.isCompany == true) {
          address.company = this.form.company;
          if (this.form.taxvat != "") {
            address.vat_id = this.form.taxvat;
          }
          if (this.form.coc != "") {
            address.coc = this.form.coc;
          }
        }
        await this.$store.dispatch("cart/addShippingAddress", {
          address: address,
          reloadCart: false,
        });
        if (this.form.address.default_billing == true) {
          await this.$store.dispatch("cart/addBillingAddress", {
            address: address,
            reloadCart: true,
          });
        }
      }
    },
    async validateInvoiceAddress() {
      let isOk = true;
      if (this.isInvoiceCompany == true) {
        if (this.form.invoice.company == "") {
          isOk = false;
        }
      }
      if (this.form.invoice.firstname == "") {
        isOk = false;
      }
      if (this.form.invoice.lastname == "") {
        isOk = false;
      }
      if (this.form.invoice.address.country_code == "") {
        isOk = false;
      }
      if (this.form.invoice.address.postcode == "") {
        isOk = false;
      }
      if (this.form.invoice.address.streetDisplay == "") {
        isOk = false;
      }
      if (this.form.invoice.address.house_number == "") {
        isOk = false;
      }
      if (this.form.invoice.address.city == "") {
        isOk = false;
      }
      if (this.form.invoice.address.telephone == "") {
        isOk = false;
      }

      if (isOk == true) {
        var address = {
          company: this.form.invoice.company,
          firstname: this.form.invoice.firstname,
          lastname: this.form.invoice.lastname,
          postcode: this.form.invoice.address.postcode,
          city: this.form.invoice.address.city,
          telephone: this.form.invoice.address.telephone,
          street: `${this.form.invoice.address.streetDisplay} ${this.form.invoice.address.house_number}${this.form.invoice.address.addition}`,
          country_code: this.form.invoice.address.country_code,
        };
        if (this.form.invoice.isCompany == true) {
          address.company = this.form.invoice.company;
          if (this.form.invoice.taxvat != "") {
            address.vat_id = this.form.invoice.taxvat;
          }
          if (this.form.invoice.coc != "") {
            address.coc = this.form.invoice.coc;
          }
        }

        this.$store.dispatch("cart/addBillingAddress", {
          address: address,
          reloadCart: true,
        });

        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }
    },
    validateCompany(data) {
      if (data || data === "") {
        this.form.company = data;
      }
      if (this.isCompany == true) {
        if (this.form.company == "") {
          this.form.companyState = false;
          this.form.companyError = this.$t("company_name_required");
        } else {
          this.form.companyState = true;
        }
      } else {
        this.form.companyState = null;
      }
    },
    validateFirstname(data) {
      if (data || data === "") {
        this.form.firstname = data;
      }
      if (this.form.firstname == "") {
        this.form.firstnameState = false;
        this.form.firstnameError = this.$t("firstname_required");
      } else {
        this.form.firstnameState = true;
      }
    },
    validateLastname(data) {
      if (data || data === "") {
        this.form.lastname = data;
      }
      if (this.form.lastname == "") {
        this.form.lastnameState = false;
        this.form.lastnameError = this.$t("lastname_required");
      } else {
        this.form.lastnameState = true;
      }
    },
    validateCOCNumber(data) {
      if (data || data === "") {
        this.form.coc = data;
      }
      this.form.cocState = null;
    },
    validateStreet(data) {
      if (data || data === "") {
        this.form.address.streetDisplay = data;
      }
      if (this.form.address.streetDisplay == "") {
        this.form.address.streetDisplayState = false;
        this.form.address.streetDisplayError = this.$t("street_required");
      } else {
        this.form.address.streetDisplayState = true;
      }
    },
    validateHouseNumber(data) {
      if (data || data === "") {
        this.form.address.house_number = data;
      }
      if (this.form.address.house_number == "") {
        this.form.address.house_numberState = false;
        this.form.address.house_numberError = this.$t("house_number_required");
      } else {
        this.form.address.house_numberState = true;
      }
    },
    validateCity(data) {
      if (data || data === "") {
        this.form.address.city = data;
      }
      if (this.form.address.city == "") {
        this.form.address.cityState = false;
        this.form.address.cityError = this.$t("city_required");
      } else {
        this.form.address.cityState = true;
      }
    },
    validatePhone(data) {
      if (data || data === "") {
        this.form.address.telephone = data;
      }
      if (this.form.address.telephone == "") {
        this.form.address.telephoneState = false;
        this.form.address.telephoneError = this.$t("phone_required");
      } else if (
        this.form.address.telephone.substring(0, 3) == "+31" ||
        (this.form.address.country_code == "NL" &&
          this.form.address.telephone.substring(0, 1) !== "+")
      ) {
        if (this.form.address.telephone.match(this.regExNlPhone) == null) {
          this.form.address.telephoneState = false;
          this.form.address.telephoneError = this.$t("phone_nl_regex_test");
        } else {
          this.form.address.telephoneState = true;
        }
      } else if (
        this.form.address.telephone.substring(0, 3) == "+32" ||
        (this.form.address.country_code == "BE" &&
          this.form.address.telephone.substring(0, 1) !== "+")
      ) {
        if (!this.form.address.telephone.match(this.regExBePhone) == null) {
          this.form.address.telephoneState = false;
          this.form.address.telephoneError = this.$t("phone_be_regex_test");
        } else {
          this.form.address.telephoneState = true;
        }
      } else if (
        this.form.address.telephone.substring(0, 3) == "+49" ||
        (this.form.address.country_code == "DE" &&
          this.form.address.telephone.substring(0, 1) !== "+")
      ) {
        if (!this.form.address.telephone.match(this.regExDePhone) == null) {
          this.form.address.telephoneState = false;
          this.form.address.telephoneError = this.$t("phone_de_regex_test");
        } else {
          this.form.address.telephoneState = true;
        }
      } else if (
        this.form.address.telephone.match(this.regExGenPhone) == null
      ) {
        this.form.address.telephoneState = false;
        this.form.address.telephoneError = this.$t("phone_required");
      } else {
        this.form.address.telephoneState = true;
      }
    },
    async validatePostcodeHouseNumber() {
      if (this.showFullAddress == false) {
        const list = await validatePostcode(
          this.form.address.postcode,
          this.form.address.house_number
        );
        if (list.length == 1) {
          let address = list[0].street + " " + list[0].number;
          this.form.address.streetDisplay = list[0].street;
          if (list[0].letter != null) {
            this.form.address.addition = list[0].letter;
            address = address + list[0].letter;
          } else {
            this.form.address.addition = "";
          }

          address = address + ", " + list[0].postcode + " " + list[0].city;

          this.form.address.city = list[0].city;
          this.form.address.cityState = true;
          this.foundAddress = address;
          this.form.address.streetDisplayState = true;
          this.validatePostcode();
        } else if (list.length == 0) {
          this.foundAddress = this.$t("postcode_not_found");
        } else {
          this.foundAddresses = [];
          let tempAddresses = [];
          list.forEach(function(item) {
            if (this.form.address.addition == "") {
              let address = item.street + " " + item.number;
              if (item.letter != null) {
                address = address + "-" + item.letter;
              }
              if (item.addition != null) {
                address =
                  address + (item.letter == null ? "-" : "") + item.addition;
              }
              address = address + ", " + item.postcode + " " + item.city;
              item.text = address;
              tempAddresses.push(item);
            } else {
              Logger.debug("validatePostcodeHouseNumber", "Not Logged In", {
                item,
                letter:
                  item.letter != null &&
                  item.letter.toUpperCase() ==
                    this.form.address.addition.toUpperCase(),
                addition:
                  item.addition != null &&
                  item.addition.toUpperCase() ==
                    this.form.address.addition.toUpperCase(),
              })();
              if (
                (item.letter != null &&
                  item.letter.toUpperCase() ==
                    this.form.address.addition.toUpperCase()) ||
                (item.addition != null &&
                  item.addition.toUpperCase() ==
                    this.form.address.addition.toUpperCase()) ||
                (item.letter != null &&
                  item.addition != null &&
                  item.letter.toUpperCase() + item.addition.toUpperCase() ==
                    this.form.address.addition.toUpperCase())
              ) {
                let address = item.street + " " + item.number;
                if (item.letter != null) {
                  address = address + "-" + item.letter;
                }
                if (item.addition != null) {
                  address =
                    address + (item.letter == null ? "-" : "") + item.addition;
                }
                address = address + ", " + item.postcode + " " + item.city;
                item.text = address;
                this.form.address.streetDisplay = item.street;
                this.form.address.streetDisplayState = true;
                this.form.address.city = item.city;
                this.form.address.cityState = true;
                this.form.address.street = item.street;
                this.foundAddress = address;
                this.validatePostcode();
                return;
              }
            }
          }, this);

          // Sort the addresses before pushing them
          tempAddresses.sort((a, b) => {
            if (a.number === b.number) {
              const letterComparison = a.letter.localeCompare(b.letter);
              if (letterComparison !== 0) return letterComparison;

              // Sort by addition if both have the same letter
              return a.addition.localeCompare(b.addition, undefined, {
                numeric: true,
              });
            }
            return a.number - b.number;
          });

          this.foundAddresses.push(...tempAddresses);
          if (
            this.foundAddresses.length == 0 &&
            this.foundAddress.length == 0
          ) {
            this.form.address.streetDisplay = "";
            this.form.address.streetDisplayState = null;
            this.form.address.city = "";
            this.form.address.cityState = null;
            this.foundAddress = this.$t("postcode_not_found");
          }
        }
      } else {
        if (this.form.address.postcode == "") {
          this.form.address.postcodeState = false;
          this.form.address.postcodeError = this.$t("postcode_required");
        } else {
          this.form.address.postcodeState = true;
        }
      }
    },
    validatePostcode(data) {
      if (data || data === "") {
        this.form.address.postcode = data;
      }

      const nl = /^[1-9][0-9]{3} ?(?!sa|sd|ss)[a-z]{2}$/i;
      const de = /(?!01000|99999)(0[1-9]\d{3}|[1-9]\d{4})/g;
      const be = /^[1-9][0-9]{3}$/g;
      if (
        this.form.address.country_code == "NL" &&
        nl.test(this.form.address.postcode)
      ) {
        this.form.address.postcodeState = true;
      } else if (
        this.form.address.country_code == "BE" &&
        be.test(this.form.address.postcode)
      ) {
        this.form.address.postcodeState = true;
      } else if (
        this.form.address.country_code == "DE" &&
        de.test(this.form.address.postcode)
      ) {
        this.form.address.postcodeState = true;
      } else {
        this.form.address.postcodeState = false;
        this.form.address.postcodeError = this.$t("postcode_required");
      }
    },
    async validateInvoiceVAT(data) {
      if (data || data === "") {
        this.form.invoice.taxvat = data;
      }
      if (this.isInvoiceCompany == true && data) {
        if (this.form.invoice.taxvat != "") {
          if (
            this.form.invoice.taxvat.substring(0, 2).toUpperCase() ===
            this.form.invoice.address.country_code
          ) {
            this.form.invoice.taxvat = this.form.invoice.taxvat.replace(
              /[.\s]/g,
              ""
            );
            this.$refs.invoicevatInput.$el.value = this.form.invoice.taxvat;

            const retval = await validateVatNumber(this.form.invoice.taxvat);
            if (retval.is_valid == true) {
              this.isValidInvoiceVatNumber = true;
              this.form.invoice.taxvatState = true;
            } else {
              this.isValidInvoiceVatNumber = false;
              this.form.invoice.taxvatState = false;
              this.form.invoice.taxvatError = this.$t("taxvat_invalid");
            }
          } else {
            this.isValidInvoiceVatNumber = false;
            this.form.invoice.taxvatState = false;
            this.form.invoice.taxvatError = this.$t("taxvat_wrong_country");
          }
        } else {
          this.form.taxvatState = null;
          this.form.taxvatError = "";
        }
      } else {
        this.form.invoice.taxvatState = null;
      }
    },
    validateInvoiceCompany(data) {
      if (data || data === "") {
        this.form.invoice.company = data;
      }
      if (this.isInvoiceCompany == true) {
        if (this.form.invoice.company == "") {
          this.form.invoice.companyState = false;
          this.form.invoice.companyError = this.$t("company_name_required");
        } else {
          this.form.invoice.companyState = true;
        }
      } else {
        this.form.invoice.companyState = null;
      }
    },
    validateInvoiceFirstname(data) {
      if (data || data === "") {
        this.form.invoice.firstname = data;
      }
      if (this.form.invoice.firstname == "") {
        this.form.invoice.firstnameState = false;
        this.form.invoice.firstnameError = this.$t("firstname_required");
      } else {
        this.form.invoice.firstnameState = true;
      }
    },
    validateInvoiceLastname(data) {
      if (data || data === "") {
        this.form.invoice.lastname = data;
      }
      if (this.form.invoice.lastname == "") {
        this.form.invoice.lastnameState = false;
        this.form.invoice.lastnameError = this.$t("lastname_required");
      } else {
        this.form.invoice.lastnameState = true;
      }
    },
    validateInvoiceCOCNumber(data) {
      if (data || data === "") {
        this.form.invoice.coc = data;
      }
      this.form.invoice.cocState = null;
    },
    validateInvoiceStreet(data) {
      if (data || data === "") {
        this.form.invoice.address.streetDisplay = data;
      }
      if (this.form.invoice.address.streetDisplay == "") {
        this.form.invoice.address.streetDisplayState = false;
        this.form.invoice.address.streetDisplayError = this.$t(
          "street_required"
        );
      } else {
        this.form.invoice.address.streetDisplayState = true;
      }
    },
    validateInvoiceHouseNumber(data) {
      if (data || data === "") {
        this.form.invoice.address.house_number = data;
      }
      if (this.form.invoice.address.house_number == "") {
        this.form.invoice.address.house_numberState = false;
        this.form.invoice.address.house_numberError = this.$t(
          "house_number_required"
        );
      } else {
        this.form.invoice.address.house_numberState = true;
      }
    },
    validateInvoiceCity(data) {
      if (data || data === "") {
        this.form.invoice.address.city = data;
      }
      if (this.form.invoice.address.city == "") {
        this.form.invoice.address.cityState = false;
        this.form.invoice.address.cityError = this.$t("city_required");
      } else {
        this.form.invoice.address.cityState = true;
      }
    },
    validateInvoicePhone(data) {
      if (data || data === "") {
        this.form.invoice.address.telephone = data;
      }
      if (this.form.invoice.address.telephone == "") {
        this.form.invoice.address.telephoneState = false;
        this.form.invoice.address.telephoneError = this.$t("phone_required");
      } else if (
        this.form.invoice.address.telephone.substring(0, 3) == "+31" ||
        (this.form.invoice.address.country_code == "NL" &&
          this.form.invoice.address.telephone.substring(0, 1) !== "+")
      ) {
        if (
          this.form.invoice.address.telephone.match(this.regExNlPhone) == null
        ) {
          this.form.invoice.address.telephoneState = false;
          this.form.invoice.address.telephoneError = this.$t(
            "phone_nl_regex_test"
          );
        } else {
          this.form.invoice.address.telephoneState = true;
        }
      } else if (
        this.form.invoice.address.telephone.substring(0, 3) == "+32" ||
        (this.form.invoice.address.country_code == "BE" &&
          this.form.invoice.address.telephone.substring(0, 1) !== "+")
      ) {
        if (
          !this.form.invoice.address.telephone.match(this.regExBePhone) == null
        ) {
          this.form.invoice.address.telephoneState = false;
          this.form.invoice.address.telephoneError = this.$t(
            "phone_be_regex_test"
          );
        } else {
          this.form.invoice.address.telephoneState = true;
        }
      } else if (
        this.form.invoice.address.telephone.substring(0, 3) == "+49" ||
        (this.form.invoice.address.country_code == "DE" &&
          this.form.invoice.address.telephone.substring(0, 1) !== "+")
      ) {
        if (
          !this.form.invoice.address.telephone.match(this.regExDePhone) == null
        ) {
          this.form.invoice.address.telephoneState = false;
          this.form.invoice.address.telephoneError = this.$t(
            "phone_de_regex_test"
          );
        } else {
          this.form.invoice.address.telephoneState = true;
        }
      } else if (
        this.form.invoice.address.telephone.match(this.regExGenPhone) == null
      ) {
        this.form.invoice.address.telephoneState = false;
        this.form.invoice.address.telephoneError = this.$t("phone_required");
      } else {
        this.form.invoice.address.telephoneState = true;
      }
    },
    async validateInvoicePostcodeHouseNumber() {
      if (this.showFullAddress == false) {
        const list = await validatePostcode(
          this.form.invoice.address.postcode,
          this.form.invoice.address.house_number
        );
        if (list.length == 1) {
          let address = list[0].street + " " + list[0].number;
          this.form.invoice.address.streetDisplay = list[0].street;
          if (list[0].letter != null) {
            this.form.invoice.address.addition = list[0].letter;
            address = address + list[0].letter;
          } else {
            this.form.invoice.address.addition = "";
          }

          address = address + ", " + list[0].postcode + " " + list[0].city;

          this.form.invoice.address.city = list[0].city;
          this.form.invoice.address.cityState = true;
          this.foundInvoiceAddress = address;
          this.validatePostcode();
        } else if (list.length == 0) {
          this.foundInvoiceAddress = this.$t("postcode_not_found");
        } else {
          list.forEach(function(item) {
            if (this.form.invoice.address.addition == "") {
              let address = item.street + " " + item.number;
              if (item.letter != null) {
                address = address + item.letter;
              }
              address = address + ", " + item.postcode + " " + item.city;
              item.text = address;
              this.foundInvoiceAddresses.push(item);
            } else {
              if (
                item.letter != null &&
                item.letter.toUpperCase() ==
                  this.form.invoice.address.addition.toUpperCase()
              ) {
                let address = item.street + " " + item.number;
                address = address + item.letter;
                address = address + ", " + item.postcode + " " + item.city;
                item.text = address;
                this.form.invoice.address.streetDisplay = item.street;
                this.form.invoice.address.city = item.city;
                this.form.invoice.address.cityState = true;
                this.form.invoice.address.street = item.street;
                this.foundInvoiceAddress = address;
                this.validateInvoicePostcode();
                return;
              }
            }
          }, this);
          if (
            this.foundInvoiceAddresses.length == 0 &&
            this.foundInvoiceAddress.length == 0
          ) {
            this.form.invoice.address.streetDisplay = "";
            this.form.invoice.address.streetDisplayState = null;
            this.form.invoice.address.city = "";
            this.form.invoice.address.cityState = null;
            this.foundInvoiceAddress = this.$t("postcode_not_found");
          }
        }
      } else {
        if (this.form.invoice.address.postcode == "") {
          this.form.invoice.address.postcodeState = false;
          this.form.invoice.address.postcodeError = this.$t(
            "postcode_required"
          );
        } else {
          this.form.invoice.address.postcodeState = true;
        }
      }
    },
    validateInvoicePostcode(data) {
      if (data || data === "") {
        this.form.invoice.address.postcode = data;
      }
      const nl = /^[1-9][0-9]{3} ?(?!sa|sd|ss)[a-z]{2}$/i;
      const de = /(?!01000|99999)(0[1-9]\d{3}|[1-9]\d{4})/g;
      const be = /^[1-9][0-9]{3}$/g;
      if (
        this.form.invoice.address.country_code == "NL" &&
        nl.test(this.form.invoice.address.postcode)
      ) {
        this.form.invoice.address.postcodeState = true;
      } else if (
        this.form.invoice.address.country_code == "BE" &&
        be.test(this.form.invoice.address.postcode)
      ) {
        this.form.invoice.address.postcodeState = true;
      } else if (
        this.form.invoice.address.country_code == "DE" &&
        de.test(this.form.invoice.address.postcode)
      ) {
        this.form.invoice.address.postcodeState = true;
      } else {
        this.form.invoice.address.postcodeState = false;
        this.form.invoice.address.postcodeError = this.$t("postcode_required");
      }
    },
    checkFields() {
      var form = this.form;
      var errorFields = [];
      var skipFields = ["coc", "taxvat"];
      Object.keys(form).forEach((name) => {
        if (
          typeof form[name] == "object" &&
          !Array.isArray(form[name]) &&
          form[name] !== null
        ) {
          if (name == "invoice" && form.address.default_billing) {
            return;
          }
          Object.keys(form[name]).forEach((child) => {
            if (child == "companyState" && name == "address") {
              if (!this.isCompany) {
                return;
              }
            }
            if (child == "taxvat" && name == "address") {
              if (!this.isCompany) {
                return;
              }
            }
            if (child == "taxvat" && name == "invoice") {
              if (!this.isInvoiceCompany) {
                return;
              }
            }
            if (child == "companyState" && name == "invoice") {
              if (!this.isInvoiceCompany) {
                return;
              }
            }
            if (
              child.includes("State") &&
              (form[name][child] == false || form[name][child] == null)
            ) {
              if (child == "companyState" && name == "address") {
                if (!this.isCompany) {
                  return;
                }
              }
              if (child == "companyState" && name == "invoice") {
                if (!this.isInvoiceCompany) {
                  return;
                }
              }
              if (skipFields.includes(child.replace("State", ""))) {
                return;
              }
              switch (name) {
                case "invoice":
                  child = "invoice_" + child;
                  break;
                case "address":
                  child = "shipping_" + child;
                  break;
              }
              errorFields.push(this.$t(child.replace("State", "")));
            }
          });
        } else {
          if (
            name.includes("State") &&
            (form[name] == false || form[name] == null)
          ) {
            if (
              (name == "passwordState" || name == "password_confirmState") &&
              !this.isCreateAccount
            ) {
              return;
            }
            if (name == "taxvatState" && !this.isCompany) {
              return;
            }
            if (name == "companyState") {
              if (!this.isCompany) {
                return;
              }
              if (!this.isInvoiceCompany) {
                return;
              }
            }
            if (skipFields.includes(name.replace("State", ""))) {
              return;
            }
            errorFields.push(this.$t(name.replace("State", "")));
          }
        }
        if (errorFields.length > 0) {
          this.$store.commit("cart/setStoreValidation", {
            address: errorFields,
          });
        } else {
          this.$store.commit("cart/setStoreValidation", {
            address: [],
          });
        }
      });
    },
    validatePassword(data) {
      if (data || data === "") {
        this.form.password = data;
      }
      if (this.form.password.length < 8) {
        this.form.passwordState = false;
        this.form.passwordError = this.$t("password_needs");
      } else if (this.form.password.match(/[a-z]/) == null) {
        this.form.passwordState = false;
        this.form.passwordError = this.$t("password_needs");
      } else if (
        this.form.password.match(/[!"#$%&'()*+,-.:;<=>?@[\]^_`{|}~]/) == null
      ) {
        this.form.passwordState = false;
        this.form.passwordError = this.$t("password_needs");
      } else if (this.form.password.match(/[A-Z]/) == null) {
        this.form.passwordState = false;
        this.form.passwordError = this.$t("password_needs");
      } else if (this.form.password.match(/\d/) == null) {
        this.form.passwordState = false;
        this.form.passwordError = this.$t("password_needs");
      } else {
        this.form.passwordState = true;
      }
      if (this.form.password_confirm.length) {
        this.validateConfirmationPassword();
      }
    },
    validateConfirmationPassword(data) {
      if (data || data === "") {
        this.form.password_confirm = data;
      }
      if (
        this.form.password_confirm &&
        this.form.password != this.form.password_confirm
      ) {
        this.form.password_confirmState = false;
        this.form.password_confirmError = this.$t("password_confirm_not_equal");
      } else if (this.form.password_confirm == "") {
        this.form.password_confirmState = false;
        this.form.password_confirmError = this.$t("password_confirm_required");
      } else {
        this.form.password_confirmState = true;
        this.$store.commit("cart/setAccountPassword", this.form.password);
      }
    },
    setCreateAccount(value) {
      this.$store.commit("cart/setCreateAccount", value);
    },
    toggleShowPass() {
      this.form.passwordFieldType =
        this.form.passwordFieldType === "password" ? "text" : "password";
    },
    toggleShowPassCreateAccount() {
      this.form.createAccountPasswordFieldType =
        this.form.createAccountPasswordFieldType === "password"
          ? "text"
          : "password";
    },
    toggleShowConfirmPassCreateAccount() {
      this.form.createAccountConfirmPasswordFieldType =
        this.form.createAccountConfirmPasswordFieldType === "password"
          ? "text"
          : "password";
    },
  },
  computed: {
    guestEmail() {
      return this.$store.getters["cart/getEmail"];
    },
    countries() {
      return this.$store.getters["user/getCountries"];
    },
    country() {
      return this.form.address.country_code;
    },
    address() {
      return `${this.form.company}|${this.form.taxvat}|${this.form.firstname}|${this.form.lastname}|${this.form.address.country_code}|${this.form.address.city}|${this.form.address.streetDisplay}|${this.form.address.postcode}|${this.form.address.house_number}|${this.form.address.addition}|${this.form.address.telephone}||${this.form.address.city}`;
    },
    invoiceAddress() {
      return `${this.form.invoice.company}|${this.form.invoice.taxvat}|${this.form.invoice.firstname}|${this.form.invoice.lastname}|${this.form.invoice.address.country_code}|${this.form.invoice.address.city}|${this.form.invoice.address.streetDisplay}|${this.form.invoice.address.house_number}|${this.form.invoice.address.addition}|${this.form.invoice.address.telephone}||${this.form.invoice.address.city}`;
    },
    invoiceCountry() {
      return this.form.invoice.address.country_code;
    },
    postcodeHouseNumber() {
      return `${this.form.address.postcode}|${this.form.address.house_number}|${this.form.address.addition}`;
    },
    invoicePostcodeHouseNumber() {
      return `${this.form.invoice.address.postcode}|${this.form.invoice.address.house_number}|${this.form.invoice.address.addition}`;
    },
    vatNumber() {
      return this.form.taxvat;
    },
    invoiceVatNumber() {
      return this.form.invoice.taxvat;
    },
    Email() {
      return this.form.email;
    },
    isLoggedIn() {
      return this.$store.getters["user/getIsLoggedIn"];
    },
    Password() {
      return this.form.password;
    },
    PasswordConfirm() {
      return this.form.password_confirm;
    },
    isNotCreateAccount: {
      get() {
        return !this.isCreateAccount;
      },
      set() {},
    },
  },
  watch: {
    // abc(newVal) {
    //   if (newVal) {
    //     this.$nextTick(() => {
    //       this.$refs.emailInput.focus(); // Focus on the input field
    //     });
    //   } else {
    //     this.$nextTick(() => {
    //       this.$refs.emailInput2.focus(); // Focus on the input field
    //     });
    //   }
    // },
    country() {
      if (this.form.address.postcode.length > 0) {
        this.validatePostcode();
      }
      if (this.isCompany == true) {
        if (this.form.taxvat != "") {
          this.validateVAT();
        }
      }
      switch (this.form.address.country_code) {
        case "NL":
          this.postcodePlaceholder = "1111AA";
          this.phonenumberPlaceholder = "0612345678";
          this.postcodeLength = 6;
          this.showFullAddress = false;
          break;
        case "BE":
          this.postcodePlaceholder = "1111";
          this.phonenumberPlaceholder = "+3212345678";
          this.postcodeLength = 4;
          this.showFullAddress = true;
          break;
        case "DE":
          this.postcodePlaceholder = "11111";
          this.phonenumberPlaceholder = "+491234567890";
          this.postcodeLength = 5;
          this.showFullAddress = true;
          break;
        default:
          this.postcodePlaceholder = "1111AA";
          this.phonenumberPlaceholder = "0612345678";
          this.postcodeLength = 6;
      }
    },
    address() {
      if (this.form.addressTimer !== null) {
        clearTimeout(this.form.addressTimer);
      }
      this.form.addressTimer = setTimeout(
        function(scope) {
          scope.validateAddress();
        },
        1000,
        this
      );
    },
    invoiceAddress() {
      if (this.form.invoiceAddressTimer !== null) {
        clearTimeout(this.form.invoiceAddressTimer);
      }
      this.form.invoiceAddressTimer = setTimeout(
        function(scope) {
          scope.validateInvoiceAddress();
        },
        1000,
        this
      );
    },
    invoiceCountry() {
      if (this.form.invoice.address.postcode.length > 0) {
        this.validateInvoicePostcode();
      }
      if (this.isInvoiceCompany == true) {
        if (this.form.invoice.taxvat != "") {
          this.validateInvoiceVAT();
        }
      }
    },
    vatNumber() {
      if (config.vat_validation.enabled) {
        if (this.form.taxvatTimer !== null) {
          clearTimeout(this.form.taxvatTimer);
        }
        this.form.taxvatTimer = setTimeout(
          function(scope) {
            scope.validateVAT();
          },
          1000,
          this
        );
      }
    },
    invoiceVatNumber() {
      if (config.vat_validation.enabled) {
        if (this.form.invoice.taxvatTimer !== null) {
          clearTimeout(this.form.invoice.taxvatTimer);
        }
        this.form.invoice.taxvatTimer = setTimeout(
          function(scope) {
            scope.validateInvoiceVAT();
          },
          1000,
          this
        );
      }
    },
    postcodeHouseNumber(newVal) {
      if (this.showFullAddress == false) {
        if (this.typeTimer !== null) {
          clearTimeout(this.typeTimer);
        }
        if (this.foundAddress != "") {
          this.foundAddress = "";
        }
        if (this.foundAddresses.length > 0) {
          this.foundAddresses = [];
        }
        //const [oldPostcode, oldHouseNumber] = oldVal.split("|");
        const [newPostcode, newHouseNumber] = newVal.split("|");
        if (newPostcode != "" && newHouseNumber != "") {
          this.typeTimer = setTimeout(
            function(scope) {
              scope.validatePostcodeHouseNumber();
            },
            2000,
            this
          );
        }
      }
    },
    invoicePostcodeHouseNumber(newVal) {
      if (this.showFullAddress == false) {
        if (this.typeTimer !== null) {
          clearTimeout(this.typeTimer);
        }
        if (this.foundInvoiceAddress != "") {
          this.foundInvoiceAddress = "";
        }
        if (this.foundInvoiceAddresses.length > 0) {
          this.foundInvoiceAddresses = [];
        }
        //const [oldPostcode, oldHouseNumber] = oldVal.split("|");
        const [newPostcode, newHouseNumber] = newVal.split("|");
        if (newPostcode != "" && newHouseNumber != "") {
          this.typeTimer = setTimeout(
            function(scope) {
              scope.validateInvoicePostcodeHouseNumber();
            },
            2000,
            this
          );
        }
      }
    },
    selectedAddress(newVal) {
      this.form.address.streetDisplay = this.foundAddresses[newVal].street;
      if (this.foundAddresses[newVal].letter != null) {
        this.form.address.addition = this.foundAddresses[newVal].letter;
      } else {
        this.form.address.addition = "";
      }
      this.form.address.city = this.foundAddresses[newVal].city;
      this.validatePostcode();
    },
    selectedInvoiceAddress(newVal) {
      this.form.invoice.address.streetDisplay = this.foundInvoiceAddresses[
        newVal
      ].street;
      if (this.foundInvoiceAddresses[newVal].letter != null) {
        this.form.invoice.address.addition = this.foundInvoiceAddresses[
          newVal
        ].letter;
      } else {
        this.form.invoice.address.addition = "";
      }
      this.form.invoice.address.city = this.foundAddresses[newVal].city;
      this.validateInvoicePostcode();
    },
    // Email() {
    //   if (this.form.emailTimer !== null) {
    //     clearTimeout(this.form.emailTimer);
    //   }
    //   this.form.emailTimer = setTimeout(
    //     function(scope) {
    //       scope.validateEmail();
    //     },
    //     1000,
    //     this
    //   );
    // },
    Password() {
      this.validatePassword();
    },
    PasswordConfirm() {
      this.validateConfirmationPassword();
    },
  },
  created() {
    this.setCreateAccount(this.isCreateAccount);
  },
  data() {
    return {
      couponCode: "",
      isCompany: false,
      isInvoiceCompany: false,
      isValidVatNumber: false,
      isValidInvoiceVatNumber: false,
      showFullAddress: false,
      showFullInvoiceAddress: false,
      showVatMessage: "",
      showVatCheck: false,
      addressTimer: null,
      invoiceAddressTimer: null,
      emailReg: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
      regExNlPhone: /^((\+|00)31[1-9][0-9]{8}||0[1-9][0-9][- ]?[1-9][0-9]{6}||0[1-9][0-9]{2}[- ][1-9][0-9]{5}||06[- ]?[1-9][0-9]{7})$/,
      regExBePhone: /^(((\+|00)32\s?|0)(\d\s?\d{3}|\d{2}\s?\d{2})(\s?\d{2}){2}||((\+|00)32\s?|0)4(60|[789]\d)(\s?\d{2}){3})$/,
      regExDePhone: /^((\+|00)49[1-9][0-9]{9}||0[1-9][0-9]{9}||015[1-9][0-9]{8}||01(6|7)[1-9][0-9]{7,8}||(\+|00)491(6|7)[1-9][0-9]{7,8})$/,
      regExGenPhone: /^(\+|00)[1-9][0-9]+$/,
      showManual: true,
      typeTimer: null,
      foundAddresses: [],
      foundAddress: "",
      foundInvoiceAddresses: [],
      foundInvoiceAddress: "",
      emailExists: false,
      selectedAddress: null,
      selectedInvoiceAddress: null,
      SubscribeNewsletter: "no",
      isCreateAccount: true,
      form: {
        email: "",
        emailState: null,
        emailError: "",
        password: "",
        passwordState: null,
        passwordError: "",
        password_confirm: "",
        password_confirmState: null,
        password_confirmError: "",
        passwordFieldType: "password",
        createAccountPasswordFieldType: "password",
        createAccountConfirmPasswordFieldType: "password",
        emailTimer: null,
        company: "",
        companyState: null,
        companyError: "",
        companyTimer: null,
        firstname: "",
        firstnameState: null,
        firstnameError: "",
        firstnameTimer: null,
        lastname: "",
        lastnameState: null,
        lastnameError: "",
        lastnameTimer: null,
        coc: "",
        cocState: null,
        cocError: "",
        cocTimer: null,
        taxvat: "",
        taxvatState: null,
        taxvatError: "",
        taxvatTimer: null,
        address: {
          country_code: "",
          postcode: "",
          postcodeState: null,
          postcodeError: "",
          postcodeTimer: null,

          street: "",
          streetDisplay: "",
          streetDisplayState: null,
          streetDisplayError: "",
          streetDisplayTimer: null,

          house_number: "",
          house_numberState: null,
          house_numberError: "",
          house_numberTimer: null,

          addition: "",
          city: "",
          cityState: null,
          cityError: "",
          cityTimer: null,

          telephone: "",
          telephoneState: null,
          telephoneError: "",
          telephoneTimer: null,
          default_billing: true,
          default_shipping: true,
        },
        invoice: {
          isCompany: true,

          company: "",
          companyState: null,
          companyError: "",
          companyTimer: null,
          firstname: "",
          firstnameState: null,
          firstnameError: "",
          firstnameTimer: null,
          lastname: "",
          lastnameState: null,
          lastnameError: "",
          lastnameTimer: null,
          coc: "",
          cocState: null,
          cocError: "",
          cocTimer: null,
          taxvat: "",
          taxvatState: null,
          taxvatError: "",
          taxvatTimer: null,
          address: {
            country_code: "",
            postcode: "",
            postcodeState: null,
            postcodeError: "",
            postcodeTimer: null,

            street: "",
            streetDisplay: "",
            streetDisplayState: null,
            streetDisplayError: "",
            streetDisplayTimer: null,

            house_number: "",
            house_numberState: null,
            house_numberError: "",
            house_numberTimer: null,

            addition: "",
            city: "",
            cityState: null,
            cityError: "",
            cityTimer: null,

            telephone: "",
            telephoneState: null,
            telephoneError: "",
            telephoneTimer: null,
            default_billing: true,
            default_shipping: true,
          },
        },
        is_subscribed: false,
        checkbox2: false,
        manual: false,
      },
      postcodePlaceholder: "1111AA",
      phonenumberPlaceholder: "0612345678",
      postcodeLength: 6,
    };
  },
};
</script>
<style lang="scss" scoped>
.account-inputs {
  .account-radios {
    width: 400px;
  }
  div {
    margin-left: 14px;
  }
}
</style>
