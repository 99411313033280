<template>
  <div class="second-col">
    <div class="shipping-form" :class="{ 'invalid-border': isShippingInvalid }">
      <div class="order-title active" @click="toggleCollapse">
        <span class="trans-180"
          ><font-awesome-icon icon="truck" flip="horizontal"
        /></span>
        {{ $t("shipping_method") }}
      </div>
      <div class="radio-btn-mob hide">
        <div class="shipping-method">
          <div v-if="deliveryOptions.length == 0 && !isLoggedIn == true">
            {{ $t("fill_address_information") }}
          </div>
          <div
            v-for="(deliveryOption, index) of deliveryOptions"
            :key="`ship-${index}`"
            class="custom-radio"
          >
            <input
              type="radio"
              name="radio-group"
              v-model="selectedShippingMethod"
              :id="`ship-${index}`"
              :value="`${deliveryOption.carrier_code}|${deliveryOption.method_code}`"
              class="custom-control-input"
              :disabled="disabledRadio"
            />
            <label :for="`ship-${index}`" class="custom-control-label">
              <div class="payment-icon">
                {{ formatCurrency(deliveryOption.amount.value) }}
              </div>
              &nbsp;
              <span>
                {{ deliveryOption.method_title }}
              </span>
            </label>
            <div
              class="shipping-method d-none"
              v-if="
                showPickupLocation &&
                `${deliveryOption.carrier_code}|${deliveryOption.method_code}` ==
                  'instore|pickup'
              "
            >
              <span class="d-block mb-2">{{ $t("select_store") }}</span>
              <b-form-group class="single-method select--custom">
                <b-form-select
                  v-model="pickupLocation"
                  :options="pickupLocations"
                  class="mb-3"
                  value-field="pickup_location_code"
                  text-field="name"
                  id="pickup-dropdown"
                  disabled
                >
                  <template #first>
                    <b-form-select-option :value="null">{{
                      $t("select_store")
                    }}</b-form-select-option>
                  </template>
                </b-form-select>
              </b-form-group>
            </div>
            <div>
              <div
                class="address-list store-address d-none"
                v-if="
                  showPickupLocation &&
                  selectedPickupLocation &&
                  `${deliveryOption.carrier_code}|${deliveryOption.method_code}` ==
                    'instore|pickup'
                "
              >
                <div class="address-list-wrap">
                  <div class="address-item" v-if="selectedAddress != null">
                    <label>{{ pickupLocationName }}</label>
                    <div>{{ selectedAddress.street[0] }}</div>
                    <div>
                      {{ selectedAddress.postcode }} {{ selectedAddress.city }}
                    </div>
                    <div v-if="selectedAddress.country">
                      {{ selectedAddress.countryName }}
                    </div>
                    <div>
                      T:<a :href="`tel:` + pickupLocationPhone">
                        {{ pickupLocationPhone }}</a
                      >
                    </div>
                    <div v-if="selectedAddress.vat_id != null">
                      VAT:{{ selectedAddress.vat_id }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- form-second -->
    <div class="payment-form" :class="{ 'invalid-border': isPaymentInvalid }">
      <div class="order-title active" @click="toggleCollapse">
        <span class="pay-icon"
          ><font-awesome-icon :icon="['fab', 'cc-visa']"
        /></span>
        {{ $t("payment_methods") }}
      </div>

      <div v-if="selectedShippingMethod != null" class="payment-method hide">
        <div
          v-for="(paymentOption, index) in paymentOptions"
          :key="`pay-${index}`"
          class="single-method"
        >
          <div class="custom-radio">
            <input
              type="radio"
              :id="`pay-${index}`"
              name="radio-group2"
              :value="paymentOption.code"
              v-on:change="checkAllSubOptions(paymentOption)"
              v-model="selectedPaymentMethod"
              class="custom-control-input"
            />
            <label :for="`pay-${index}`" class="custom-control-label">
              <div class="payment-icon">
                <img
                  :src="`${app_url}/media/graphql/icons/50x32/${paymentOption.code}.png`"
                  :alt="paymentOption.code"
                />
              </div>
              <span>{{ paymentOption.label }}</span>
            </label>
          </div>
          <template v-if="hasSelectedPaymentSubOptions">
            <div
              class="select-bank"
              v-for="(
                paymentSubOption, index
              ) in paymentOption.additional_fields"
              :key="`pso-${index}`"
              :ref="'subOptions-' + paymentOption.code"
            >
              <div v-if="paymentSubOption.type == 'select'">
                <label v-if="paymentOption.label !== 'iDEAL'">{{
                  paymentSubOption.label
                }}</label>
                <label v-else>{{ $t("pay_own_bank") }}</label>
                <b-form-group class="select--custom">
                  <b-form-select
                    :options="paymentSubOption.options"
                    text-field="label"
                    :value="
                      multisafepayIdealValue ||
                      paymentMethodOptions[paymentOption.code][
                        paymentSubOption.code
                      ]
                    "
                    v-on:change="
                      setPaymentMethodOptions(
                        paymentOption.code,
                        paymentSubOption.code,
                        $event
                      )
                    "
                  >
                    <template #first>
                      <b-form-select-option :value="null">{{
                        $t("select_bank")
                      }}</b-form-select-option>
                    </template>
                  </b-form-select>
                </b-form-group>
              </div>
            </div>
          </template>
        </div>
      </div>
      <div v-else class="note hide">
        {{ $t("fill_shipping_options") }}
      </div>
    </div>
  </div>
</template>
<script>
import config from "@config";
import Cart from "@storefront/core/modules/cart/mixins";

export default {
  name: "OrderShippingComponent",
  props: {
    isShippingInvalid: Boolean,
    isPaymentInvalid: Boolean,
  },
  data() {
    return {
      app_url: config.app_url,
      form: {
        name: "",
        checked: [],
      },
      city: [{ text: "Nederland", value: null }, "Nederland", "Nederland"],
      hasSelectedPaymentSubOptions: false,
      showPickupLocation: false,
      selectedPickupLocation: null,
      pickupLocation: null,
      multisafepayIdealValue: "",
      disabledRadio: false,
    };
  },
  mixins: [Cart],
  computed: {
    isLoggedIn() {
      return this.$store.getters["user/getIsLoggedIn"];
    },
    paymentOptions: {
      get() {
        var options = this.$store.getters["cart/getPaymentMethods"];
        if (options == null) {
          this.$store.commit("setShippingMethod", this.selectedShippingMethod);
        }
        return options;
      },
      set() {},
    },
    deliveryOptions() {
      return this.$store.getters["cart/getShippingMethods"];
    },
    paymentMethodOptions() {
      return this.$store.getters["cart/getPaymentMethodOptions"];
    },
    shippingMethod() {
      return this.$store.getters["cart/getShippingMethod"];
    },

    selectedShippingMethod: {
      get: function () {
        const shmed = this.$store.getters["cart/getShippingMethod"];
        if (shmed != null) {
          return shmed.carrier_code + "|" + shmed.method_code;
        } else {
          return null;
        }
      },
      set: async function (value) {
        this.disabledRadio = true;
        if (value !== null) {
          this.paymentOptions = this.$store.getters["cart/getPaymentMethods"];
        }
        const delOption = value.split("|");
        if (delOption[1] != "pickup") {
          localStorage.removeItem("pickupLocationCode");
          this.pickupLocation = null;
          this.showPickupLocation = false;
          this.selectedPickupLocation = false;
        }
        const retval = await this.$store.dispatch("cart/addShippingMethod", {
          carrier: delOption[0],
          method: delOption[1],
        });
        if (this.$gtm.enabled()) {
          let prod = [];
          if (this.cartItems && this.cartItems.length > 0) {
            this.cartItems.forEach((element) => {
              let i = {
                id: element.product.sku,
                name: element.product.name,
                quantity: element.quantity,
              };
              prod.push(i);
            });
            window.dataLayer.push({
              event: "eec.checkout",
              ecommerce: {
                checkout: {
                  actionField: {
                    step: 3,
                    option: value,
                    action: "checkout",
                  },
                  products: prod,
                },
              },
            });
          }
        }

        if (retval) {
          this.disabledRadio = false;
        }
      },
    },
    selectedPaymentMethod: {
      get: function () {
        return this.$store.getters["cart/getPaymentMethod"];
      },
      set: function (value) {
        this.$store.commit("cart/setPaymentMethod", value);
        if (this.$gtm.enabled()) {
          let prod = [];
          if (this.cartItems && this.cartItems.length > 0) {
            this.cartItems.forEach((element) => {
              let i = {
                id: element.product.sku,
                name: element.product.name,
                quantity: element.quantity,
              };
              prod.push(i);
            });
            window.dataLayer.push({
              event: "eec.checkout",
              ecommerce: {
                checkout: {
                  actionField: {
                    step: 2,
                    option: value,
                    action: "checkout",
                  },
                  products: prod,
                },
              },
            });
          }
        }
      },
    },
    btnDisable() {
      if (this.$store.getters["cart/getShippingAddress"] == null) {
        return true;
      }
      if (this.$store.getters["cart/getBillingAddress"] == null) {
        return true;
      }
      if (this.$store.getters["cart/getShippingMethod"] == null) {
        return true;
      }
      if (this.$store.getters["cart/getPaymentMethod"] == null) {
        return true;
      }
      if (this.$store.getters["cart/getEmail"] == null) {
        return true;
      }
      if (this.$store.getters["cart/getItemsTotalQuantity"] == 0) {
        return true;
      }

      return false;
    },
    pickupLocations() {
      let locations = [...this.$store.getters["cart/getPickupLocations"]];
      locations = locations.map((location) => {
        return {
          ...location,
          name: location.name,
          phone: location.phone,
        };
      });

      let newLocations = [];
      let sortVal = config.pickupSortOptions;
      sortVal.forEach((item) => {
        const index = locations.findIndex(
          (location) =>
            location.pickup_location_code.toLowerCase() == item.toLowerCase()
        );
        if (index > -1) newLocations.push(locations.splice(index, 1)[0]);
      });

      newLocations = [...newLocations, ...locations];

      return newLocations;
    },
    pickupLocationName() {
      if (this.pickupLocation && this.pickupLocation.length > 0) {
        return this.pickupLocations.find(
          (loc) => loc.pickup_location_code === this.pickupLocation
        ).name;
      } else {
        return "";
      }
    },
    pickupLocationPhone() {
      if (this.pickupLocation && this.pickupLocation.length > 0) {
        return this.pickupLocations.find(
          (loc) => loc.pickup_location_code === this.pickupLocation
        ).phone;
      } else {
        return "";
      }
    },
    getSelectedShippingMethod() {
      const shmed = this.$store.getters["cart/getShippingMethod"];
      return shmed;
    },
    selectedAddress: {
      get: function () {
        let address = { ...this.$store.getters["cart/getShippingAddress"] };
        return address;
      },
      set: function () {
        //Nothing to do
      },
    },
  },
  watch: {
    selectedShippingMethod(method) {
      if (!method) {
        return;
      }
      const [carrier_code, method_code] = method.split("|");
      if (carrier_code == "instore" && method_code == "pickup") {
        // this.showPickupLocation = true;
        if (this.pickupLocations && this.pickupLocations.length == 1) {
          let code = this.pickupLocations[0].pickup_location_code;
          this.pickupLocation = code;
          if (this.pickupLocations) {
            const response = this.$store.dispatch("cart/setPickupLocation", {
              code,
            });
            if (response) {
              const delOption = this.selectedShippingMethod.split("|");
              this.$store.dispatch("cart/addShippingMethod", {
                carrier: delOption[0],
                method: delOption[1],
              });
              this.selectedPickupLocation =
                this.$store.getters["cart/getShippingAddress"];
              let data = {
                shipping: this.selectedShippingMethod,
                pickupLocation: code,
                selectedAddress: this.selectedAddress,
              };
              let jsonData = JSON.stringify(data);
              localStorage.setItem("pickupLocationCode", jsonData);

              this.$emit("toggleNextDisable", false);
            }
          }
        }
      } else {
        this.showPickupLocation = false;
        this.$store.dispatch("cart/addShippingMethod", {
          carrier: carrier_code,
          method: method_code,
        });
      }
    },
    selectedPaymentMethod: {
      handler: function () {
        this.hasSelectedPaymentSubOptions = false;
      },
      immediate: true,
    },
    address() {
      if (this.paymentOptions == []) {
        this.$store.commit("setShippingMethod", this.selectedShippingMethod);
        this.paymentOptions = this.$store.getters["cart/getPaymentMethods"];
      }
    },
    async pickupLocation(code) {
      if (code) {
        const response = await this.$store.dispatch("cart/setPickupLocation", {
          code,
        });
        if (response) {
          const delOption = this.selectedShippingMethod.split("|");
          await this.$store.dispatch("cart/addShippingMethod", {
            carrier: delOption[0],
            method: delOption[1],
          });
          this.selectedPickupLocation = await this.$store.getters[
            "cart/getShippingAddress"
          ];
          let data = {
            shipping: this.selectedShippingMethod,
            pickupLocation: code,
            selectedAddress: this.selectedAddress,
          };
          let jsonData = JSON.stringify(data);
          localStorage.setItem("pickupLocationCode", jsonData);

          this.$emit("toggleNextDisable", false);
        }
      } else {
        // const msg = {
        // type: "danger",
        // text: this.$t("Pickup_Location_is_not_valid"),
        // };
        // this.$store.dispatch("messages/sendMessage", { message: msg });
        console.log(this.$t("Pickup_Location_is_not_valid"));
      }
    },
  },
  methods: {
    formatCurrency(amount) {
      return this.$helpers.formatCurrency(amount);
    },
    async submitForm() {
      const retval = await this.$store.dispatch("cart/addPaymentMethod");
      if (retval != false) {
        const url = retval.redirect_url.replace(config.app_url, "");
        if (this.isLoggedIn == true) {
          this.$store.dispatch("user/loadOrders", { page: 1, perPage: 10 });
        }
        this.$store.dispatch("cart/unLoad");
        if (url.includes("https://")) {
          window.location = url;
        } else {
          this.$store.dispatch("cart/loadCart");
          this.$router.push(
            url + "?transactionid=" + retval.order.increment_id
          );
        }
      }
    },
    async addCoupon() {
      if (this.couponCode != "") {
        const retval = await this.$store.dispatch("cart/addCouponCode", {
          code: this.couponCode,
        });
        if (retval == true) {
          const msg = {
            type: "success",
            title: this.$t("coupon_code"),
            text: this.$t("coupon_code_ok", { code: this.couponCode }),
          };
          this.$store.dispatch("messages/sendMessage", { message: msg });
          this.couponCode = "";
        }
      }
    },
    setPaymentMethodOptions(key, sub, value) {
      this.$store.commit("cart/updateCartPaymentMethodOptions", {
        key: key,
        sub: sub,
        value: value,
      });
    },
    checkAllSubOptions() {
      if (this.selectedPaymentMethod) {
        localStorage.setItem(
          "checkoutPayment",
          JSON.stringify(this.selectedPaymentMethod)
        );
      }
      this.hasSelectedPaymentSubOptions = false;
    },
    submitCoupon(e) {
      e.preventDefault();
    },
    toggleCollapse(event) {
      if (window.innerWidth <= 768) {
        if (!event.target.classList.contains("active")) {
          event.target.classList.add("active");
        } else {
          event.target.classList.remove("active");
        }
      }
    },
    checkFields() {
      var errorFields = [];
      if (!this.shippingMethod) {
        errorFields.push(this.$t("Shipping method"));
      }
      if (!this.selectedPaymentMethod) {
        errorFields.push(this.$t("Payment method"));
      }
      if (errorFields.length > 0) {
        this.$store.commit("cart/setStoreValidation", {
          payment: errorFields,
        });
      } else {
        this.$store.commit("cart/setStoreValidation", {
          payment: [],
        });
      }
    },
  },
  mounted() {
    // Set pickup location if already exist
    let localPickupData = localStorage.getItem("pickupLocationCode");
    if (localPickupData) {
      let parsedData = JSON.parse(localPickupData);
      this.pickupLocation = parsedData.pickupLocation;
      this.selectedAddress = parsedData.selectedAddress;
      this.selectedPickupLocation = parsedData.selectedAddress;
    } else {
      this.pickupLocation = null;
      this.showPickupLocation = false;
      this.selectedAddress = "";
    }

    this.$root.$on("checkFields", () => {
      this.checkFields();
    });
    const shmed = this.getSelectedShippingMethod;
    if (shmed != null) {
      if (shmed.carrier_code == "instore" && shmed.method_code == "pickup") {
        this.showPickupLocation = true;
      }
      this.selectedShippingMethod =
        shmed.carrier_code + "|" + shmed.method_code;
    }
  },
};
</script>
