<template>
  <div class="">
    <div class="search-filter" :class="{ show: isActive }">
      <div class="search-filter-categories">
        <div class="product-filters">
          <h3 class="category-heading mobile-hide">{{ $t("search_help") }}</h3>
          <div class="tab-mob-show">
            <h3 class="category-heading border-none pb-15">
              {{ $t("search_help") }}
              <span class>
                <b-nav-item
                  class="close-sidebar tab-mob-show"
                  @click="closeSearchSidebar"
                >
                  <b-img alt :src="closeIcon" />
                </b-nav-item>
              </span>
            </h3>
          </div>
          <div class="heading bold pt-3 pb-15">
            <button @click="resetFilter" class="filter-btn">
              {{ $t("clear_filter") }}
            </button>
          </div>
          <div
            class="filterCheck"
            v-for="prodFilter of productFilters"
            :key="prodFilter.attribute_code"
          >
            <div class="price" v-if="prodFilter.attribute_code == 'price'">
              <div :class="prodFilter.attribute_code">
                <div class="check-list-hd-category-checkbox">
                  <h6
                    class="list-hd-category-checkbox"
                    v-b-toggle="prodFilter.attribute_code"
                  >
                    <span class="category-icon">
                      <font-awesome-icon icon="caret-down" />
                    </span>
                    <span>{{ $t(prodFilter.label) }}</span>
                  </h6>
                  <b-collapse visible :id="prodFilter.attribute_code">
                    <div class="range-slider">
                      <div class="range-values d-flex justify-content-between">
                        <span
                          ><input
                            ref="priceFilter"
                            type="number"
                            :min="priceFilterMin"
                            :max="priceFilterMax"
                            @input="debounceMin"
                            :value="priceRange[0]"
                        /></span>
                        <span
                          ><input
                            ref="priceFilter"
                            type="number"
                            :min="priceFilterMin"
                            :max="priceFilterMax"
                            @input="debounceMax"
                            :value="priceRange[1]"
                        /></span>
                      </div>
                      <ClientOnly>
                        <VueRangeSlider
                          :min="priceFilterMin"
                          :max="priceFilterMax"
                          :lazy="true"
                          :formatter="formatter"
                          v-model="priceRange"
                          :logarithmic="true"
                          tooltip="active"
                          :enableCross="false"
                          :enable-cross="false"
                          ref="rangeSlider"
                        >
                        </VueRangeSlider>
                      </ClientOnly>
                    </div>
                  </b-collapse>
                </div>
              </div>
            </div>
            <div class="size" v-if="prodFilter.attribute_code != 'price'">
              <div class="check-list-hd-category-checkbox">
                <h6
                  class="list-hd-category-checkbox"
                  v-b-toggle="prodFilter.attribute_code"
                >
                  <span class="category-icon">
                    <font-awesome-icon icon="caret-down" />
                  </span>
                  <span>{{ prodFilter.label }}</span>
                </h6>
                <b-collapse visible :id="prodFilter.attribute_code">
                  <div
                    class="filterdata"
                    v-for="(brandsFilterItem, index) in readMore[
                      prodFilter.attribute_code
                    ]
                      ? prodFilter.options
                      : prodFilter.options.slice(0, 5)"
                    :key="index"
                  >
                    <label
                      :for="brandsFilterItem.value"
                      class="d-flex align-items-center"
                    >
                      <input
                        type="checkbox"
                        :key="brandsFilterItem.value"
                        :name="brandsFilterItem.label"
                        :id="brandsFilterItem.value"
                        :ref="brandsFilterItem.value"
                        :value="brandsFilterItem.value"
                        @click="
                          onChangeFilter($event, {
                            attribute_code: prodFilter.attribute_code,
                            type_label: prodFilter.label,
                            value: brandsFilterItem.value,
                            label: brandsFilterItem.label,
                          })
                        "
                      />
                      <span
                        class="fake-input"
                        :style="{
                          background:
                            brandsFilterItem.swatch_data &&
                            brandsFilterItem.swatch_data.value,
                          borderColor:
                            brandsFilterItem.swatch_data &&
                            brandsFilterItem.swatch_data != '#ffffff' &&
                            brandsFilterItem.swatch_data.value,
                        }"
                      ></span>
                      <span class="label-wrap">
                        <span class="fake-label">{{
                          brandsFilterItem.label
                        }}</span>
                        <span class="number-available"
                          >({{ brandsFilterItem.count }})</span
                        >
                      </span>
                    </label>
                  </div>

                  <div v-if="prodFilter.count > 5" class="show-more">
                    <b-link
                      v-if="readMore[prodFilter.attribute_code]"
                      @click="toggleReadMore(prodFilter.attribute_code)"
                      >{{ $t("less") }}</b-link
                    >
                    <b-link
                      v-else
                      @click="toggleReadMore(prodFilter.attribute_code)"
                    >
                      {{ $t("show") }} {{ prodFilter.count - 5 }}
                      {{ $t("more") }}
                    </b-link>
                  </div>
                </b-collapse>
              </div>
            </div>
          </div>
        </div>
        <SidebarRatingComponent />
      </div>

      <button
        @click="closeSearchSidebar"
        class="filter-btn show-more-results mob-tab-show"
      >
        {{ $t("look_at") }} {{ totalProducts }} {{ $t("result") }}
        <b-icon icon="chevron-right"></b-icon>
      </button>
    </div>
  </div>
</template>

<script>
import closeIcon from "@/esf_utrecht_bankxl/assets/images/close-icon.png";
import SidebarRatingComponent from "@/esf_utrecht_bankxl/core/components/products/category-product/SidebarRatingComponent";
import ClientOnly from "vue-client-only";
import VueRangeSlider from "vue-slider-component/dist-css/vue-slider-component.umd.min.js";
import { debounce } from "lodash";

export default {
  name: "ProductFilterSidebar",
  components: {
    SidebarRatingComponent,
    ClientOnly,
    VueRangeSlider,
  },
  inject: ["openSearchSidebar", "closeSearchSidebar"],
  props: {
    isActive: Boolean,
  },
  mounted() {
    this.filterItemsSelected.forEach((filterItem) => {
      if (this.$refs[filterItem.value] && this.$refs[filterItem.value][0]) {
        this.$refs[filterItem.value][0].checked = true;
      }
    });
  },
  created() {
    this.min = 0;
    this.max = 250;
    this.enableCross = false;
    this.formatter = "€ {value}";
    this.tooltipDir = "bottom";
  },
  methods: {
    onChangeFilter(event, filter) {
      if (typeof filter != "undefined" && typeof filter.value != "undefined") {
        if (event.target.checked == true) {
          this.$store.commit("search/setActiveFilter", filter);
        } else {
          this.$store.commit("search/removeActiveFilter", filter);
        }
      }
      this.updateUrlFilters();
    },
    debounceMin: debounce(function(event) {
      this.onPriceRangeMinChange(event);
    }, 500),

    debounceMax: debounce(function(event) {
      this.onPriceRangeMaxChange(event);
    }, 500),
    onPriceRangeMinChange(event) {
      if (
        Number(event.target.value) <= this.priceRange[1] &&
        Number(event.target.value) >= Number(event.target.min)
      ) {
        this.priceRange[0] = Number(event.target.value);
        this.processPricePriceRangeChange(this.priceRange);
        this.$refs.rangeSlider[0].setValue(this.priceRange);
      }
    },
    onPriceRangeMaxChange(event) {
      if (
        Number(event.target.value) >= this.priceRange[0] &&
        Number(event.target.value) <= Number(event.target.max)
      ) {
        this.priceRange[1] = Number(event.target.value);
        this.processPricePriceRangeChange(this.priceRange);
        this.$refs.rangeSlider[0].setValue(this.priceRange);
      }
    },
    processPricePriceRangeChange(newValue) {
      if (this.$refs.priceFilter) {
        const inputMin = this.$refs.priceFilter[0];
        if (inputMin && parseInt(newValue[0]) <= parseInt(newValue[1])) {
          this.$store.commit("search/setPriceFilter", [...newValue]);
          const { path } = this.$route;
          const query = { ...this.$route.query };

          if (this.isPriceFilterActive)
            query["filter-price"] = `${newValue[0]}-${newValue[1]}`;
          else delete query["filter-price"];
          delete query["page"];

          this.$router.replace({ path, query });
        }
      }
    },
    toggleReadMore(attribute_code) {
      if (this.readMore[attribute_code]) {
        this.readMore = {
          ...this.readMore,
          [attribute_code]: false,
        };
      } else {
        this.readMore = {
          ...this.readMore,
          [attribute_code]: true,
        };
      }
    },
    resetFilter() {
      Object.keys(this.$refs).forEach((el) => {
        if (
          this.$refs[el][0] &&
          this.$refs[el][0].type === "checkbox" &&
          this.$refs[el][0].checked
        ) {
          this.$refs[el][0].checked = false;
        }
      });

      this.$store.commit("search/resetActiveFilter");
      this.$store.commit("search/resetCurrentPage");
      this.$store.dispatch("search/loadSearchFilters");

      const activeFilters = {};
      this.filterItemsSelected.forEach((val) => {
        if (activeFilters[val.attribute_code]) {
          activeFilters[val.attribute_code].push(val.value);
        } else {
          activeFilters[val.attribute_code] = [val.value];
        }
      });

      const { path } = this.$route;
      const query = { ...this.$route.query };

      for (const q in query) {
        if (q.startsWith("filter-")) delete query[q];
      }
      for (const attr in activeFilters) {
        query[`filter-${attr}`] = activeFilters[attr].toString();
      }
      delete query["page"];

      this.$router.replace({ path, query });
    },
    addPriceFilters() {
      const newFilters = this.$store.getters["search/getProductFilters"];
      if (Array.isArray(newFilters)) {
        const priceFilter = newFilters.find(
          (f) => f.attribute_code === "price"
        );
        if (
          !this.priceProdFilter ||
          !Object.keys(this.priceProdFilter).length
        ) {
          this.priceProdFilter = { ...priceFilter };
        }
      }
    },
    updatePriceFilter() {
      const priceFilter = this.$route.query["filter-price"];
      if (priceFilter && this.priceProdFilter && this.priceProdFilter.options) {
        const selectedPrice = [];
        this.priceProdFilter.options.forEach((opt) => {
          const [priceMin, priceMax] = priceFilter.split("-");
          const [min, max] = opt.value.split("_");
          if (+min >= +priceMin && +max <= +priceMax) {
            selectedPrice.push(opt.value);
          }
        });
        this.selectedPrice = selectedPrice;
      }
    },
    updateUrlFilters() {
      const activeFilters = {};
      this.filterItemsSelected.forEach((val) => {
        if (activeFilters[val.attribute_code]) {
          activeFilters[val.attribute_code].push(val.value);
        } else {
          activeFilters[val.attribute_code] = [val.value];
        }
      });

      const { path } = this.$route;
      const query = { ...this.$route.query };

      for (const q in query) {
        if (q.startsWith("filter-") && q !== "filter-price") {
          delete query[q];
        }
      }
      for (const attr in activeFilters) {
        query[`filter-${attr}`] = activeFilters[attr].toString();
      }
      delete query["page"];

      this.$router.replace({ path, query });
    },
    formatCurrency(amount) {
      return this.$helpers.formatCurrency(amount, false);
    },
  },
  data() {
    return {
      value: [0, 200],
      closeIcon,
      show: true,
      sliderIsLoaded: false,
      bla: false,
      minAngle: 10,
      maxAngle: 30,
      filterExpanded: false,
      readMore: {},
      minRangeFilter: 0,
      priceRange: [0, 0],
    };
  },
  computed: {
    filterItemsSelected() {
      return this.$store.getters["search/getActiveFilter"];
    },
    isPriceFilterActive() {
      return this.$store.getters["search/getIsPriceFilterActive"];
    },
    priceFilterMin() {
      return this.$store.getters["search/getPriceFilterMin"];
    },
    priceFilterMax() {
      return this.$store.getters["search/getPriceFilterMax"];
    },
    searchWord() {
      if (typeof this.$route.query.q != "undefined") {
        return this.$route.query.q;
      } else {
        return "";
      }
    },
    priceFilter() {
      return this.$store.getters["search/getPriceFilter"];
    },
    productFilters() {
      var productFilters = JSON.parse(
        JSON.stringify(this.$store.getters["search/getProductFilters"])
      );
      if (productFilters.length) {
        productFilters.forEach((filter) => {
          if (filter.options) {
            filter.options.sort((a, b) => {
              return a.label < b.label ? -1 : 1;
            });
          }
        });
      }
      return productFilters;
    },
    totalProducts() {
      return this.$store.getters["search/getTotalProducts"];
    },
  },
  updated() {
    if (this.filterItemsSelected && this.filterItemsSelected.length) {
      this.filterItemsSelected.forEach((filter) => {
        if (this.$refs[filter.value] && this.$refs[filter.value][0]) {
          this.$refs[filter.value][0].checked = true;
        }
      });
    }
  },
  watch: {
    priceFilter(val) {
      this.priceRange = val;
    },
    priceRange(newValue) {
      if (
        !(
          newValue[0] === this.priceFilter[0] &&
          newValue[1] === this.priceFilter[1]
        )
      ) {
        this.processPricePriceRangeChange(newValue);
      }
    },
  },
};
</script>
