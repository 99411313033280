<template>
  <div class="product-gallery">
    <div
      class="selected-image-holder"
      @click.prevent="showMultiple(selectedImage.index)"
    >
      <div class="product-tag desktop-product-tag">
        <div class="tag" v-if="product.cashback_promotion">
          <span class="discount-txt"
            >{{
              formatCurrency(product.cashback_promotion.amount.value) +
                " cashback"
            }}
          </span>
        </div>
        <div
          class="product-tag-wrap"
          v-if="product.labels && product.labels.length"
        >
          <div
            class="product-tag-single"
            v-for="(label, idx) in product.labels"
            :key="idx"
          >
            <div v-if="label.product.type === 'image'" class="img-label">
              <div
                class="tag-label"
                :class="label.product.position"
                :style="
                  `width:${label.product.size}px;
                      height:${label.product.size}px;
                      background-image: url('` +
                    label.product.image_url +
                    `');
                      ${label.product.css}`
                "
              />
            </div>
            <div v-if="label.product.type === 'text'" class="text-label">
              <div
                class="tag-label"
                :class="label.product.position"
                :style="label.product.css"
              >
                {{ label.product.text }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="selected-image"
        :style="{ backgroundImage: `url(${selectedImage.url})` }"
      ></div>
    </div>
    <ClientOnly>
      <Lightbox
        :media="seeImage"
        :index="index"
        @onClosed="handleHide()"
        @onOpened="onLightboxOpened()"
        :key="lbKey"
        :showThumbs="false"
        :showCaption="true"
        ref="lightbox"
        :show-light-box="false"
        :closable="true"
        :interfaceHideTime="3000000"
      >
        <template v-slot:close
          ><span>
            <svg
              fill="white"
              x="0px"
              y="0px"
              width="100%"
              height="100%"
              viewBox="0 0 512 512"
              style="enable-background: new 0 0 512 512"
            >
              <path
                d="M443.6,387.1L312.4,255.4l131.5-130c5.4-5.4,5.4-14.2,0-19.6l-37.4-37.6c-2.6-2.6-6.1-4-9.8-4c-3.7,0-7.2,1.5-9.8,4 L256,197.8L124.9,68.3c-2.6-2.6-6.1-4-9.8-4c-3.7,0-7.2,1.5-9.8,4L68,105.9c-5.4,5.4-5.4,14.2,0,19.6l131.5,130L68.4,387.1 c-2.6,2.6-4.1,6.1-4.1,9.8c0,3.7,1.4,7.2,4.1,9.8l37.4,37.6c2.7,2.7,6.2,4.1,9.8,4.1c3.5,0,7.1-1.3,9.8-4.1L256,313.1l130.7,131.1 c2.7,2.7,6.2,4.1,9.8,4.1c3.5,0,7.1-1.3,9.8-4.1l37.4-37.6c2.6-2.6,4.1-6.1,4.1-9.8C447.7,393.2,446.2,389.7,443.6,387.1z"
              />
            </svg> </span
        ></template>
        <template v-slot:previous
          ><span>
            <svg
              fill="white"
              x="0px"
              y="0px"
              width="100%"
              height="100%"
              viewBox="0 0 512 512"
            >
              <path
                d="M213.7,256L213.7,256L213.7,256L380.9,81.9c4.2-4.3,4.1-11.4-0.2-15.8l-29.9-30.6c-4.3-4.4-11.3-4.5-15.5-0.2L131.1,247.9 c-2.2,2.2-3.2,5.2-3,8.1c-0.1,3,0.9,5.9,3,8.1l204.2,212.7c4.2,4.3,11.2,4.2,15.5-0.2l29.9-30.6c4.3-4.4,4.4-11.5,0.2-15.8 L213.7,256z"
              />
            </svg> </span
        ></template>
        <template v-slot:next>
          <span>
            <svg
              fill="white"
              x="0px"
              y="0px"
              width="100%"
              height="100%"
              viewBox="0 0 512 512"
            >
              <path
                d="M298.3,256L298.3,256L298.3,256L131.1,81.9c-4.2-4.3-4.1-11.4,0.2-15.8l29.9-30.6c4.3-4.4,11.3-4.5,15.5-0.2l204.2,212.7 c2.2,2.2,3.2,5.2,3,8.1c0.1,3-0.9,5.9-3,8.1L176.7,476.8c-4.2,4.3-11.2,4.2-15.5-0.2L131.3,446c-4.3-4.4-4.4-11.5-0.2-15.8 L298.3,256z"
              />
            </svg> </span
        ></template>
      </Lightbox>
      <VueSlickCarousel
        :arrows="true"
        :dots="false"
        :draggable="false"
        :slidesToShow="4.5"
        :slidesToScroll="1"
        :focusOnSelect="true"
        :vertical="true"
        :verticalSwiping="false"
        :infinite="false"
        :responsive="[
          {
            breakpoint: 1199,
            settings: {
              slidesToShow: 4.5,
              autoplay: true,
            },
          },
          {
            breakpoint: 768,
            settings: {
              arrows: true,
              dots: true,
              slidesToShow: 1,
              autoplay: true,
              vertical: false,
              verticalSwiping: false,
              infinite: true,
            },
          },
          {
            breakpoint: 540,
            settings: {
              arrows: true,
              dots: true,
              slidesToShow: 1,
              autoplay: true,
              vertical: false,
              verticalSwiping: true,
              infinite: true,
            },
          },
        ]"
      >
        <div
          class="single-image"
          v-for="(item, i) in carouselImages"
          :key="'slide' + i"
        >
          <b-link class="image" @click="selectImage(i)">
            <img :src="item.url" :alt="item.label" />
            <div class="overlay" v-if="item.type == 'video'">
              <b-icon icon="play-fill" class="icon"></b-icon>
            </div>
          </b-link>
        </div>
      </VueSlickCarousel>
    </ClientOnly>
    <p
      v-if="productDiscount > 1"
      class="desktop-show desktop main-product offer-tag"
    >
      <b-link class="offer-link"> -{{ productDiscount.toFixed(0) }}% </b-link>
    </p>
  </div>
</template>

<script>
import ClientOnly from "vue-client-only";
import { isServer } from "@storefront/core/helpers";
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import logo from "@/esf_utrecht_bankxl/assets/images/logo1.png";
import("vue-it-bigger/dist/vue-it-bigger.min.css");

export default {
  name: "ProductGallery",
  props: ["images", "productDiscount", "video", "product"],
  components: {
    ClientOnly,
    VueSlickCarousel,
    Lightbox: () => (!isServer ? import("vue-it-bigger") : null),
  },
  data() {
    return {
      logo,
      seeImage: [],
      carouselImages: [],
      selectedImage: {
        url: this.images.length > 0 ? this.images[0].url : "",
        index: 0,
      },
      imgs: [],
      visible: false,
      index: 0,
      slideNumber: 0,
      lbKey: 0,
      lbOpen: false,
      settings_acc_slider: {
        centerMode: false,
        dots: false,
        infinite: false,
        speed: 500,
        controls: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        initialSlide: 0,
        adaptiveHeight: true,
        rtl: false,
        responsive: [
          {
            breakpoint: 992,
            settings: {
              slidesToShow: 2.5,
              infinite: true,
              dots: false,
            },
          },
          {
            breakpoint: 767,
            settings: {
              slidesToShow: 1,
              dots: false,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      },
    };
  },
  watch: {
    carouselImages() {
      this.selectedImage = { url: this.carouselImages[0].url, index: 0 };
    },
    images() {
      this.updateGallery();
    },
  },
  methods: {
    selectImage(id) {
      if (this.carouselImages[id].type != "video") {
        this.selectedImage = { url: this.carouselImages[id].url, index: id };
        this.index = id;
      } else {
        this.index = id;
        this.showMultiple(id);
      }
      if (window.innerWidth < 767) {
        this.showMultiple(id);
      }
    },

    showMultiple(id) {
      this.seeImage = [];
      this.images.forEach((number, index) => {
        const totalSlides = this.images.length;
        const slide = index + 1;

        const label = {
          src: number.url,

          // title: number.label + " (" + slide + "/" + totalSlides + " )",
          caption: `${number.label || ""} ( ${slide} / ${totalSlides} )`,
          type: "image",
        };

        this.seeImage.push(label);
      });
      if (this.video) {
        const label = {
          id: this.video.replace(
            /^(?:http(?:s)?:\/\/)?(?:www\.)?(?:m\.)?(?:youtu\.be\/|youtube\.com\/(?:(?:watch)?\?(?:.*&)?v(?:i)?=|(?:embed|v|vi|user|shorts)\/))([^?&"'>]+)/,
            "$1"
          ),
          thumb:
            "https://img.youtube.com/vi/" +
            this.video.replace(
              /^(?:http(?:s)?:\/\/)?(?:www\.)?(?:m\.)?(?:youtu\.be\/|youtube\.com\/(?:(?:watch)?\?(?:.*&)?v(?:i)?=|(?:embed|v|vi|user|shorts)\/))([^?&"'>]+)/,
              "$1"
            ) +
            "/hqdefault.jpg",
          // title: number.label + " (" + slide + "/" + totalSlides + " )",
          caption: `${"Video"} ( ${this.images.length + 1} / ${this.images
            .length + 1} )`,
          type: "youtube",
        };

        this.seeImage.push(label);
      }
      this.imgs = this.carouselImages.map((image) => image.url);

      // this.index = this.slideNumber;

      this.show(id);
    },
    show(id) {
      this.$refs.lightbox.showImage(id);
    },
    handleHide() {
      this.seeImage = [];
      this.visible = false;
      if (!this.lbOpen) return;
      this.lbOpen = false;
      this.lbKey++;
    },
    updateGallery() {
      var carouselImages = [];
      this.images.forEach((image) => {
        image.type = "image";
        carouselImages.push(image);
      });
      if (this.video && this.video.length) {
        let videoObject = {
          label: null,
          url:
            "https://img.youtube.com/vi/" +
            this.video.replace(
              /^(?:http(?:s)?:\/\/)?(?:www\.)?(?:m\.)?(?:youtu\.be\/|youtube\.com\/(?:(?:watch)?\?(?:.*&)?v(?:i)?=|(?:embed|v|vi|user|shorts)\/))([^?&"'>]+)/,
              "$1"
            ) +
            "/hqdefault.jpg",
          type: "video",
        };
        carouselImages.push(videoObject);
      }
      this.carouselImages = carouselImages;
    },
    formatCurrency(amount) {
      return this.$helpers.formatCurrency(amount, false);
    },
  },
  mounted() {
    this.updateGallery();
  },
};
</script>

<style>
.is-active .al img {
  filter: grayscale(0%);
}
.al img {
  /* filter: grayscale(100%);*/
  margin: 2px 0;
}

.carousel {
  display: grid;
  grid-template-columns: auto 25%;
  align-items: center;
}

.carousel-indicator {
  flex-direction: column;
}

.indicator-item {
  margin-right: initial !important;
}
</style>
